<template>
    <nav class="main-nav" role="navigation">
        <!-- Sample menu definition -->
        <ul ref="main-menu" class="sm sm-simple">
            <li><a :href="getToUrl(null)">Hockey TV</a></li>
            <li><a style="cursor:pointer;">Liga auswählen</a>
                <ul>
                    <iframe-topbar-item v-for="menuItemId in getMenuTreeKeys()" :key="menuItemId" :channel-slug="channelSlug" :item-data="getMenuTreeObject(menuItemId)"></iframe-topbar-item>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
    import gql from 'graphql-tag'
    import AbstractSportFilter from './AbstractSportFilter'
    import IframeTopbarItem from './IframeTopbarItem'
    import {pathJoin} from "../../utils";
    import * as $ from 'jquery';
    import smartmenus from 'smartmenus';

    export default {
        name: "IframeTopbar",
        extends: AbstractSportFilter,
        components: {
            IframeTopbarItem
        },
        props: {
            channelSlug: {
                type: String,
                required: true
            },
            menuTree: {}
        },
        data() {
            return {
                leagues: [],
                clubs: []
            }
        },
        methods: {
            getMenuTreeKeys(){
                return Object.keys(this.menuTree)
            },
            getMenuTreeObject(key){
                return this.menuTree[key];
            },
            redirectToUrl(item) {
                if (item == null)
                    window.location.href = pathJoin(['/embed', this.channelSlug]);
                else if (item.slug)
                    window.location.href = pathJoin(['/embed', this.channelSlug, item.slug]);
                else if (item.id)
                    window.location.href = pathJoin(['/embed', this.channelSlug, item.id]);
            },
            getToUrl(item) {
                if (item == null)
                    return pathJoin(['/embed', this.channelSlug]);
                else if (item.slug)
                    return pathJoin(['/embed', this.channelSlug, item.slug]);
            },
            getIndex(prefix, index) {
                return prefix + "-" + index
            },
            initializeMenu() {
                let self = this;
                this.$nextTick(function () {
                    $(function () {
                        $(self.$refs['main-menu']).smartmenus('refresh');
                    });
                });

            }
        },
        mounted: function () {
            let self = this;
            $(self.$refs['main-menu']).smartmenus();
        }
    }
</script>