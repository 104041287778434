'use strict';

import videojs from 'video.js';

let Menu = videojs.getComponent('Menu');
let MenuButton = videojs.getComponent('MenuButton');

export default class SettingsMenu extends MenuButton {

    constructor(player, options) {
        super(player, options);
        this.render();
    }

    createMenu() {
        let menu = new Menu(this.player());
        menu.addChild('GoalsOnlyMenuItem', {
            parent: menu,
            menu: menu
        });
        return menu;
    }

    render() {
        let button = this.contentEl().getElementsByTagName('button')[0];
        button.innerHTML = button.innerHTML + `<i class="button--settings material-icons">settings</i>`;
    }

}

videojs.registerComponent('SettingsMenu', SettingsMenu);