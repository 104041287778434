<template>
    <div class="row">
        <div class="list-sports col-16">
            <p v-if="!result_data.hits">Loading...</p>
            <div v-else v-for="sport in getSports()" class="list-sports__item">
                <a :href="getSportUrl(sport.slug)" class="link">
                    <div class="list-sports__circle">
                        <i class="fa" :class="sport.icon"></i>
                    </div>
                    <h6 v-text="gettext(sport.name)"></h6>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import AbstractResult from './AbstractResult'
    import {pathJoin} from "../../utils";

    export default {
        name: "PopularSportsResult",
        extends: AbstractResult,
        data() {
            return {
                result_name: 'popular_sports',
                es_format: false
            }
        },
        computed: {
            all_sports() {
                return this.$store.state.filter_store.all_sports;
            },
        },
        methods: {
            gettext(text) {
                return window.gettext(text);
            },
            getSportUrl(sport) {
                let country = this.$store.state.filter_store.search_data.popular_sports.filter.hasOwnProperty('country_slug') ? this.$store.state.filter_store.search_data.popular_sports.filter.country_slug : '';
                return pathJoin([window.urls.channelList, country, sport])
            },
            getSports() {
                return this.all_sports.filter((element) => {
                    return this.result_data.hits.includes(element.slug);
                }).sort((a, b) => {
                    if (this.result_data.hits.indexOf(a.slug) > this.result_data.hits.indexOf(b.slug)) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            }
        }
    }
</script>