<script>
    import SocialSharing from 'vue-social-sharing';
    import BaseNetworks from './video_player/social-network';
    import CustomSocialSharingNetwork from './video_player/custom-social-sharing-network'
    export default {
        data () {
            return {
                /**
                * Available sharing networks.
                * @param object
                */
                baseNetworks: BaseNetworks,

                /**
                * Popup settings.
                * @param object
                */
                popup: {
                    status: false,
                    resizable: true,
                    toolbar: false,
                    menubar: false,
                    scrollbars: false,
                    location: false,
                    directories: false,
                    width: 626,
                    height: 436,
                    top: 0,
                    left: 0,
                    window: undefined,
                    interval: null
                }
            };
        },
        name: "CustomSocialSharing",
        extends: SocialSharing,
        methods: {
            share (network) {
              this.$root.$emit('social_shares_open', network, this.url);
              this.$emit('open', network, this.url);
              this.$root.$on('virendered', (network, url) => {
                    this.openSharer(network, this.createSharingUrl(network));
              });
            },
        },
        components: {
        'network': CustomSocialSharingNetwork
      }
    }

</script>