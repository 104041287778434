'use strict';

import videojs from 'video.js'
import _ from "lodash";

let Component = videojs.getComponent('Component');

class OverlayBanner extends Component {

    constructor(player, options) {
        super(player, options);
        this.overlayTime = options.overlayTime;
        this.hide();
    }

    createEl() {
        let el = super.createEl('div');
        el.appendChild(document.getElementById('overlay-banner').childNodes[0]);
        return el;
    }

}

videojs.registerComponent('OverlayBanner', OverlayBanner);