<script>
    export default {
        name: "VideoModal",
        props: {
            url: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                open: false,
                modalContainer: null,
                isYoutube: false
            }
        },
        watch: {
            open(newVal){
                if (this.$mq === 'sm' || this.$mq === 'md') {
                    if(newVal){
                        document.body.classList.add("no-scroll")
                        document.documentElement.classList.add("no-scroll")
                    }
                    else{
                        document.body.classList.remove("no-scroll")
                        document.documentElement.classList.remove("no-scroll")
                    } 
                }
            }
        },
        methods: {
            openVideo() {
                if (this.isYoutube) {
                    this.$refs.video.src = this.url;
                } else {
                    this.$refs.video.currentTime = 0;
                    this.$refs.video.play();
                }
                this.open = true;
            },
            closeVideo() {
                this.open = false;
                if (this.isYoutube) {
                    this.$refs.video.src = "";
                } else {
                    this.$refs.video.pause();
                }
            }
        },
        mounted() {
            this.modalContainer = this.$refs.videoModal;
            (document.getElementById('app')).appendChild(this.modalContainer);
            if (this.url.includes('youtube')) {
                this.isYoutube = true;
            }
        }

    }
</script>