'use strict';

import videojs from 'video.js';

let SettingsMenuItem = videojs.getComponent('SettingsMenuItem');

class GoalsOnlyMenuItem extends SettingsMenuItem {

    constructor(player, options) {
        super(player, options);
        this.isActive_ = false;
        this.template = _.template(document.getElementById('menu-item-template').innerHTML);
        this.render();
    }

    handleClick() {
        super.handleClick();
        this.isActive_ = !this.isActive_;
        if(this.isActive_) {
            this.player().trigger('filterVideos', {filter: 'goals'});
        }else{
            this.player().trigger('filterVideos', {filter: 'all'});
        }
        this.render();
    }

    isActive() {
        return this.isActive_;
    }

    render() {
        const isActive = this.isActive_;
        const className = isActive ? 'active' : '';
        const icon = `<i class="fas fa-futbol"></i>`;
        const text = 'goals only';
        this.contentEl().innerHTML = this.template({
            icon: icon,
            text: text,
            className: className
        });
    }

}

videojs.registerComponent('GoalsOnlyMenuItem', GoalsOnlyMenuItem);