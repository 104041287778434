'use strict';

import videojs from 'video.js';
import template from 'lodash/template';

let Navigation = videojs.getComponent('Navigation');

export default class NavigationPrev extends Navigation {

    constructor(player, options) {
        super(player, options);
        this.render()
    }

    handleClick() {
        this.player().trigger('navigationPrev');
    }

    createEl() {
        return super.createEl('div', {
            className: 'video-control__navigation-button'
        });
    }

    render() {
        this.el().innerHTML = template(document.getElementById('navigation-prev-template').innerHTML)();
    }

}

videojs.registerComponent('NavigationPrev', NavigationPrev);
