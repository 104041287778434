<template>
    <el-select
            :value="country_slug"
            @change="setCountry($event)"
            @blur="selectFix"
            clearable
            filterable
            size="small"
            auto-complete="NoAutocomplete"
            :placeholder="gettext('Select option')"
            class="select footer__select">
        <i slot="prefix" class="el-input__icon fas fa-map-marker-alt"></i>
        <el-option
                v-for="item in activeCountries"
                :key="item.id"
                :label="item.name"
                :value="item.slug">
        </el-option>
    </el-select>
</template>

<script>
    import {filterable_select_fix} from "../utils";
    import {main_filter_mixin} from './filters/main_filter_mixin';
    const FILTERS_DEPENDING_ON_COUNTRY = ['most_viewed', 'recent_games', 'popular_games', 'popular_sports', 'top_sports_footer', 'top_leagues', 'top_channels'];

    export default {
        mixins: [filterable_select_fix, main_filter_mixin],
        name: "CountrySelector",
        props: {
            country: {
                type: String,
                required: false
            }
        },
        computed: {
            country_slug() {
                return this.$store.state.filter_store.country;
            },
            allCountries() {
                return this.$store.state.filter_store.filter_countries
            }
        },
        methods: {
            gettext(text) {
                return window.gettext(text);
            },
            setCountry(value) {
                if (value != this.country_slug) {
                    localStorage.setItem('country', value);
                    this.$store.dispatch('filter_store/setCountry', {
                        country: value,
                        result_names: FILTERS_DEPENDING_ON_COUNTRY
                    });
                }
            }
        },
        async created() {
            await this.$store.dispatch('filter_store/fetchAllSportsCountries');
            let sessionCountry = localStorage.getItem('country');
            if (this.country !== this.country_slug && sessionCountry == null) {
                this.$store.dispatch('filter_store/setCountry', {
                    country: this.country,
                    result_names: FILTERS_DEPENDING_ON_COUNTRY
                });
            } else if (sessionCountry != null && sessionCountry != this.country_slug) {
                this.$store.dispatch('filter_store/setCountry', {
                    country: sessionCountry,
                    result_names: FILTERS_DEPENDING_ON_COUNTRY
                });
            } else if(this.$store.state.filter_store.initCountrySelect){
                this.$store.dispatch('filter_store/setCountry', {
                    country: '',
                    result_names: FILTERS_DEPENDING_ON_COUNTRY
                });
            }
        }
    }
</script>