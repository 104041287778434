'use strict';

import videojs from 'video.js';
import LC from 'literallycanvas';
import ReactDOM from 'react-dom';

let Button = videojs.getComponent('Button');

class PaintButton extends Button {

    constructor(player, options) {
        super(player, options);
        this.isActive_ = false;
        this.template = _.template(document.getElementById('menu-item-template').innerHTML);
        this.render();
    }

    handleClick() {
        super.handleClick();
        this.isActive_ = !this.isActive_;
        this.render();
        if (this.isActive_) {
            let player = this.player();
            player.pause();
            if (typeof player.overlay === "function") {
                player.overlay().getOverlays().forEach(function (entry) {
                    entry.pause();
                });
            }
            this.startPainting();
        } else {
            this.endPainting();
        }
    }

    resizeCanvas(player) {
        let paintObject = player.paintObject;
        let toolbar = document.getElementsByClassName('horz-toolbar')[0];
        if (player.paintObject == null) {
            return;
        }
        let h = parseInt(getComputedStyle(player.el()).height) - player.controlBar.height();
        let h2 = parseInt(getComputedStyle(toolbar).height);
        let hDiff = h - h2;
        document.getElementsByClassName("literally")[0].style.height = h + "px";
        paintObject.canvas.height = paintObject.backgroundCanvas.height = hDiff;
        paintObject.canvas.style.height = paintObject.backgroundCanvas.style.height = hDiff + "px";
        player.getChild('PaintOverlay').show();
        paintObject.respondToSizeChange();
    }

    startPainting() {
        let player = this.player();
        let paintDiv = this.player().getChild('PaintOverlay').el();
        if (typeof navigator.languages !== "undefined" && navigator.languages.indexOf("de") >= 0) {
            LC.localize({
                "Analysis": "Analyse",
                "Save": "Speichern",
                "Clear": "LĂ¶schen",
                "stroke": "Farbe",
                "fill": "FĂĽllfarbe",
                "italic": "kursiv",
                "bold": "fett",
                "Color to change:": "Zu wechselnde Farbe:",
                "alpha": "Alpha",
                "saturation": "SĂ¤ttigung",
                "Other": "Andere",
                "Rectangle": "Rechteck",
                "Eraser": "Radierer",
                "Line": "Linie",
                "Pencil": "Stift"
            });
        }
        player.paintObject = LC.init(
            paintDiv, {
                imageURLPrefix: '/static/images',
                toolbarPosition: 'top',
                primaryColor: '#ff9600',
                secondaryColor: 'transparent',
                tools: [
                    LC.tools.Pencil,
                    LC.tools.Eraser,
                    LC.tools.Line,
                    LC.tools.Ellipse,
                    LC.tools.Rectangle,
                    LC.tools.Text,
                    LC.tools.Polygon
                ],
                strokeWidths: [1, 2, 5, 10, 20],
                watermarkImage: this.player().children().filter(function (x) {
                    return x.tagName == "VIDEO";
                })[0]
            }
        );

        player.el().style.minHeight = "430px";
        player.paintMode = true;

        this.resizeCanvas(player);
        window.onresize = () => {
            this.resizeCanvas(player);
            this.resizeCanvas(player);
        };
        player.on("fullscreenchange", () => {
            this.resizeCanvas(player)
        });
        // p.ga().sendbeacon("PaintStarted", true, Math.round(p.currentTime()));
    }

    endPainting() {
        let player = this.player();
        if (!player.paintObject) {
            return;
        }
        ReactDOM.unmountComponentAtNode(document.getElementsByClassName('player-overlay')[0]);
        player.paintObject.teardown();
        player.paintObject = null;
        player.getChild('PaintOverlay').hide();
        player.el().style.minHeight = "auto";
        this.isActive_ = false;
        this.render();
        player.play();
    }

    isActive() {
        return this.isActive_;
    }

    render() {
        const className = this.isActive_ ? 'active' : '';
        const icon = `<i class="material-icons">edit</i>`
        const text = '';
        this.contentEl().innerHTML = this.template({
            className: className,
            icon: icon,
            text: text
        });
    }
}

videojs.registerComponent('PaintButton', PaintButton);