<template>
    <div class="side-filter" id="side-filter">
        <div class="side-filter__fixed" ref="sidefilter" :style="sidebarStyle">
            <el-scrollbar :wrap-style="maxScrollHeight" id="side-filter-scroll">
                <div class="side-filter__title">Refine by</div>
                <div class="side-filter__group" :class="{ 'open' : openSideFilter }">
                    <div class="side-filter__header">
                        <div class="side-filter__subtitle">Sport</div>
                        <div class="side-filter__icon" @click="openSideFilter = !openSideFilter">
                            <i class="fas fa-minus" :class="{ 'show' : openSideFilter }"></i>
                            <i class="fas fa-plus" :class="{ 'show' : !openSideFilter }"></i>
                        </div>
                    </div>
                    <el-collapse-transition>
                        <div v-show="openSideFilter">
                            <el-radio-group :value="search_data.channel.filter['sports_slug']"
                                            @input="updateFilter($event, 'sports_slug')">
                                <el-radio v-for="(item, index) in activeSports" v-if="index < numberOfSports" :key="item.slug" :label="item.slug" class="side-filter__radio">
                                    <i class="fa" :class="item.icon"></i>
                                    {{ item.name }}
                                </el-radio>
                            </el-radio-group>
                            <button v-if="activeSports && numberOfSports < activeSports.length" @click="showMoreSports"
                                    class="link side-filter__more">+ More sports
                            </button>
                        </div>
                    </el-collapse-transition>
                </div>
                <div class="side-filter__group" :class="{ 'open' : openSideFilterCountry }">
                    <div class="side-filter__header">
                        <div class="side-filter__subtitle">Country</div>
                        <div class="side-filter__icon" @click="openSideFilterCountry = !openSideFilterCountry">
                            <i class="fas fa-minus" :class="{ 'show' : openSideFilterCountry }"></i>
                            <i class="fas fa-plus" :class="{ 'show' : !openSideFilterCountry }"></i>
                        </div>
                    </div>
                    <el-collapse-transition>
                        <div v-show="openSideFilterCountry">
                            <el-radio-group :value="search_data.channel.filter['country_slug']"
                                            @input="updateFilter($event, 'country_slug')">
                                <el-radio v-for="(item, index) in activeCountries" v-if="index < numberOfCountries" :key="item.slug" :label="item.slug"
                                          class="side-filter__radio">
                                    <img class="flag" :src="item.flag" :alt="item.code">
                                    {{ item.name }}
                                </el-radio>
                                <el-radio key="others" label="others" class="side-filter__radio">
                                    Other counties
                                </el-radio>
                            </el-radio-group>
                            <button class="link side-filter__more" v-if="activeCountries && numberOfCountries < activeCountries.length"
                                    @click="showMoreCountries">+ More countries
                            </button>
                        </div>
                    </el-collapse-transition>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import AbstractSportFilter from './AbstractSportFilter';
    import {main_filter_mixin} from './main_filter_mixin';
    import {SIDE_FILTER_OFFSET} from './../../js/const.js';

    export default {
        name: "MainSideFilter",
        mixins: [main_filter_mixin],
        extends: AbstractSportFilter,
        data() {
            return {
                loading: false,
                index_names: ['channel', 'league', 'game', 'team'],
                result_names: ['channel', 'league', 'game', 'team'],
                openSideFilter: true,
                openSideFilterCountry: true,
                filterFixed: false,
                sideHeight: 0,
                windowHeight: 0,
                sidebarStyle: {
                    position: 'relative',
                    top: 0,
                    width: 'auto'
                },
                maxScrollHeight: 'max-height: none;',
                filter: null,
                filterTop: 0,
                filterWrapperTop: 0,
                filterMargin: 0,
                footer: null,
                footerTop: 0,
                staticPlaceholder: null,
                staticPlaceholderTop: 0,
                numberOfSports: 9,
                numberOfCountries: 9,
            }
        },
        computed: {
            maxSizeFooter() {
                return this.staticPlaceholderTop - this.filterTop + SIDE_FILTER_OFFSET
            },
            maxSizeWindow() {
                return this.windowHeight - this.filterTop + SIDE_FILTER_OFFSET
            },
            comparedSize() {
                if (this.maxSizeFooter < this.maxSizeWindow) {
                    return this.maxSizeFooter
                }
                else {
                    return this.maxSizeWindow
                }
            },
        },
        watch: {
            filterWrapperTop(newTop, oldTop) {
                let navbarHeight = document.getElementById('navbar').clientHeight;
                this.maxScrollHeight = 'max-height: calc(' + this.comparedSize + 'px - 8rem); width: auto;'
                let sideFilterHeight = document.getElementById('side-filter-scroll').clientHeight;
                if (this.filterWrapperTop <= navbarHeight) {
                    this.sidebarStyle.position = 'fixed'
                    this.sidebarStyle.top = 'calc(' + navbarHeight + 'px + 4rem)'
                } else {
                    this.sidebarStyle.position = 'relative'
                    this.sidebarStyle.top = 0
                }
            },
        },
        methods: {
            scrollSideFilter() {
                let navbarHeight = document.getElementById('navbar').clientHeight;
                let footerOffsetTop = document.getElementById('footer').getBoundingClientRect().top;
                this.$nextTick(() => {
                    if (this.filterTop < parseInt(this.filterMargin) + navbarHeight) {
                        //this.filterFixed = true;
                        this.sidebarStyle.position = 'fixed'
                        this.sidebarStyle.top = navbarHeight + 'px'
                    } else {
                        this.sidebarStyle.position = 'relative'
                        this.sidebarStyle.top = 0
                    }
                })
            },
            showMoreSports: function () {
                this.numberOfSports += this.numberOfSports;
            },
            showMoreCountries: function () {
                this.numberOfCountries += this.numberOfCountries;
            },
            scrollHandler() {
                this.filterWrapperTop = this.filter.getBoundingClientRect().top;
                this.filterTop = this.$refs.sidefilter.getBoundingClientRect().top;
                this.footerTop = this.footer.getBoundingClientRect().top;
                this.staticPlaceholderTop = this.staticPlaceholder.getBoundingClientRect().top;
            }
        },
        mounted() {
            this.maxScrollHeight = 'max-height: calc(' + this.comparedSize + 'px - 8rem); width: auto;'
            this.filter = document.getElementById('side-filter');
            this.filterMargin = window.getComputedStyle(this.$refs.sidefilter).marginTop
            this.sidebarStyle.width = this.filter.clientWidth + 'px'
            this.footer = document.getElementById('footer');
            this.staticPlaceholder = document.getElementById('channel-list-static-placeholder');
            this.windowHeight = window.innerHeight;
            this.filterTop = this.filter.getBoundingClientRect().top;
            window.addEventListener('resize', () => {
                this.windowHeight = window.innerHeight
            });
            window.addEventListener('scroll', this.scrollHandler);
            this.scrollHandler();
        }
    }
</script>

<style lang="scss" scoped>
    .flag {
        width: 15px !important;
    }
</style>