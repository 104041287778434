<script>
    import Slick from "vue-slick";

    export default {
        name: "partners",
        components: { Slick },
        data() {
          return {
              partnersOptions: {
                  slidesToShow: 5,
                  infinite: true,
                  autoplay: true,
                  prevArrow: '<button type="button" class="slider-arrow slider-arrow--prev"><i class="fas fa-chevron-left"></i></button>',
                  nextArrow: '<button type="button" class="slider-arrow slider-arrow--next"><i class="fas fa-chevron-right"></i></button>',
                  responsive: [
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 4,
                        arrows: false,
                        dots: true,
                        infinite: true,
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: true,
                        infinite: true,
                      }
                    }
                  ]
                },
          }
        },
        methods: {
          openMenu() {
              this.open = true;
          },
          closeMenu() {
              this.open = false;
          }
        }
    }
</script>