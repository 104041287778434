'use strict';

import videojs from 'video.js';

let Button = videojs.getComponent('Button');

export default class LoopButton extends Button {

    constructor(player, options) {
        super(player, options);
        this.isActive_ = false;
        this.render();
    }

    handleClick() {
        super.handleClick();
        this.isActive_ = !this.isActive_;
        this.player().trigger('setLoop');
        this.render();
    }

    isActive() {
        return this.isActive_;
    }

    render() {
        const className = this.isActive_ ? 'active' : '';
        this.contentEl().innerHTML = `<i class="button--loop material-icons ${className}">autorenew </i>`
    }

}

videojs.registerComponent('LoopButton', LoopButton);
