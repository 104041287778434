<template>
    <div v-if="allClubs != null" class="row">
        <div v-for="item in allClubs.edges" class="club-section-content col-lg-2 col-sm-4 col-8">
            <a class="club-section-item" :href="embed ? item.node.iframeUrl : item.node.url">
                <div class="club-section-item__image">
                    <img :src="item.node.logo != null ? item.node.logo : item.node.logoUrl" :alt="item.node.name">
                </div>
                <div class="club-section-item__description text-center">
                    <h5>{{ item.node.name }}</h5>
                </div>
            </a>
        </div>
        <div v-if="loading" class="text-center club-section-loading"><h3>Loading...</h3></div>
        <div v-else class="channel-detail-results-load">
            <button class="button button--secondary" v-if="allClubs.pageInfo && allClubs.pageInfo.hasNextPage" @click="loadMore">
                <div class="add-button__icon"><i class="fas fa-plus"></i></div>
                <span>Load more</span></button>
        </div>
    </div>
</template>
<script>
    import gql from 'graphql-tag'

    function getFilterItemsQuery() {
        return gql`
        query ChannelClubs($channelSlug: String!, $offset: String, $step: Int!, $customSlug: String) {
            allClubs(channel_Slug: $channelSlug, first: $step, after: $offset, customSlug: $customSlug) {
                pageInfo{
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                edges{
                    node{
                        name,
                        logo,
                        url,
                        iframeUrl
                    }
                }
            }
        }`
    }

    export default {
        name: "Clubs",
        props: {
            channelSlug: {
                type: String,
                required: true
            },
            embed: {
                type: Boolean,
                required: false,
                default: false
            },
            customSlug: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                loading: 0,
                allClubs: null
            }
        },
        methods: {
            loadMore() {
                let endCursor = this.allClubs.pageInfo.endCursor
                this.$apollo.queries.allClubs.fetchMore({
                    variables: {
                        offset: endCursor
                    },
                    updateQuery: (previousQueryResult, {fetchMoreResult}) => {
                        if (!fetchMoreResult) {
                            return previousQueryResult;
                        }
                        return Object.assign({}, fetchMoreResult, {
                            allClubs: {
                                ...fetchMoreResult.allClubs,
                                edges: [...previousQueryResult.allClubs.edges, ...fetchMoreResult.allClubs.edges]
                            }
                        });
                    }
                });
            }
        },
        apollo: {
            allClubs: {
                query: getFilterItemsQuery(),
                loadingKey: 'loading',
                variables() {
                    return {
                        channelSlug: this.channelSlug,
                        step: 32,
                        offset: this.offsetLocal,
                        customSlug: this.customSlug
                    }
                }
            }
        }
    }
</script>
