<template>
    <div class="section-header">
        <div class="section-header__title">
            <h3 v-text="gettext('THE MOST POPULAR SPORTS')"></h3>
            <el-select
                    :value="search_data.popular_sports.filter['country_slug']"
                    @blur="selectFix"
                    clearable
                    filterable
                    @change="updateFilter($event, 'country_slug')"
                    size="small"
                    :placeholder="gettext('World')"
                    class="text-select hidden-xs"
                    ref="select1">
                <el-option
                        v-for="item in activeCountries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.slug">
                </el-option>
            </el-select>
            <div class="section-header__hidden-label" ref="referenceLabel" v-text="selectLabel">
            </div>
        </div>
        <div class="section-header__link hidden-xs">
            <a :href="goToChannel()" class="link"><span v-text="gettext('All sports ')"></span><i
                    class="sport-chevron-circle-right"></i></a>
        </div>
    </div>
</template>

<script>
    import AbstractSportFilter from './AbstractSportFilter'
    import {main_filter_mixin} from './main_filter_mixin';
    import {pathJoin} from "../../utils";

    export default {
        name: "PopularSportsFilter",
        mixins: [main_filter_mixin],
        extends: AbstractSportFilter,
        data() {
            return {
                result_names: ['popular_sports'],
                index_names: ['top_sports'],
                dataInitFilter: {
                    popular_sports: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country,
                        },
                        sort: {
                            sort_1: "-created"
                        },
                        size: 8
                    }
                },
            }
        },
        methods: {
            gettext(text) {
                return window.gettext(text);
            },
            goToChannel(sport) {
                return pathJoin([window.urls.channelList, this.country_slug, sport])
            }
        },
    }
</script>
