<script>
    import {filterable_select_fix} from "../utils";

    export default {
        mixins: [filterable_select_fix],
        name: "subscription-step",
        props: {
            initData: {
                default: function(){
                    return {}
                }
            }
        },
        data() {
            return {
                subscription_first_country: this.get_init_value('country'),
                subscription_first_account_type: '',
                subscription_first_sport: this.get_init_value('sport'),
                phone_select: '',
                subscription_file_name: 'No file chosen',
                platform: this.get_init_value('platform'),
            }
        },
        methods: {
            get_init_value(field){
                if(this.initData.hasOwnProperty(field)){
                    return this.initData[field];
                }else{
                    return '';
                }
            },
            fileInputLoadImage(value) {
                let image_name = value.split("\\");
                if (!image_name.length) {
                    this.subscription_file_name = 'No file chosen'
                } else {
                    this.subscription_file_name = image_name[image_name.length - 1]
                }
            }
        },
        mounted() {
        }
    }
</script>