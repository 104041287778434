<template>
    <div>
        <div class="row">
            <div class="section-header section-header--channel-results col-16">
                <h3>Games</h3>
                <div class="align-items-center justify-content-end d-flex">
                    <div class="small-text count-text">{{ (result_data && result_data.total) ? result_data.total.value : 0 }} game results</div>
                    <button @click="loadAll"
                            :class="{'button--inverse-secondary-hover': isExpanded}"
                            class="button button--inverse-secondary button--small" v-text="isExpanded ? 'Hide all' : 'Explore all'"></button>
                </div>
            </div>
        </div>
        <div class="channel-section-content">
            <div class="row">
                <div class="col-16">
                    <div class="row ">
                        <div v-for="item in result_data.hits" class="channel-section-content__item-wrapper">
                            <div class="video-tile">
                                <a class="link"
                                   :href="goToChannel(item.league.channel.slug, item.league.slug, item.slug)">
                                    <div class="video-tile__image"
                                         v-bind:style="{ backgroundImage: 'url(' + item.thumbnail + ')' }"></div>
                                    <div class="video-tile__description">
                                        <div class="video-tile__title">{{ item.name }}</div>
                                        <ul class="video-tile__text" v-if="item.league.channel">
                                            <li><img class="img-fallback" :src="item.league.channel.logo"
                                                     :alt="item.league.channel.name"><span>{{ item.league.channel.name }}</span>
                                            </li>
                                            <li class="dot" v-if="item.country">{{
                                                item.country.name }}
                                            </li>
                                        </ul>
                                        <ul class="video-tile__text">
                                            <li><i class="fas fa-eye"></i><span
                                                    v-text="get_number_formatted(item.total_views)"></span></li>
                                            <li class="dot" v-text="get_publish_date(item.created)"></li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="result_data.loading" class="col-16 text-center"><h6>Loading...</h6></div>
        <button v-else-if="showLoadMore" @click="loadMore" class="link channel-section-content__load">Load more games <i
                class="sport-chevron-circle-right"></i></button>
    </div>
</template>

<script>
    import AbstractResult from './AbstractResult'

    export default {
        name: "GamesResult",
        extends: AbstractResult,
        data() {
            return {
                result_name: 'game'
            }
        },
        methods: {
          // BYPASS CACHE SERVER
          getFallbackThumbnail(thumbnail) {
            if (thumbnail) {
              const segments = thumbnail.split('/')
              return 'https://vipr-app.s3.amazonaws.com/data/' + segments[3] + '/image/' + segments[4]
            }
            else {
              return null
            }
          }
        }
    }
</script>