<template>
    <div v-if="!mobile" :class="{'col-lg-3 offset-lg-1 col-md-4 col-sm-16 hidden-xs': !mobile}">
        <div class="footer-menu">
            <div class="footer-menu__header" v-text="header_label"></div>
            <div>
                <ul class="footer-menu__list">
                    <template v-if="result_name == 'top_sports_footer'">
                        <li v-if="result_data.loading" class="footer-menu__item ">Loading...</li>
                        <li v-else v-for="item in getSports()" class="footer-menu__item "><a
                                :href="getSportUrl(item.slug)" class="link  v-cloak--hidden">{{
                            item.name }}</a></li>
                    </template>
                    <template v-else>
                        <li v-if="result_data.loading" class="footer-menu__item ">Loading...</li>
                        <li v-else v-for="item in result_data.hits" class="footer-menu__item "><a
                                :href="item.url ? (item && item.url) : `/${item.slug}`" class="link  v-cloak--hidden">{{
                            item.name }}</a></li>
                    </template>
                </ul>
                <div class="footer-menu__link"><a :href="baseChannelUrl" class="link">{{ show_all_label }}<i
                        class="sport-chevron-circle-right"></i></a></div>
            </div>
        </div>
    </div>
    <el-collapse-item v-else :title="header_label" :name="result_name">
        <ul class="footer-menu__list">
            <template v-if="result_name == 'top_sports_footer'">
                <li v-if="result_data.loading" class="footer-menu__item ">Loading...</li>
                <li v-else v-for="item in getSports()" class="footer-menu__item "><a
                        :href="getSportUrl(item.slug)" class="link  v-cloak--hidden">{{
                    item.name }}</a></li>
            </template>
            <template v-else>
                <li v-if="result_data.loading" class="footer-menu__item ">Loading...</li>
                <li v-else v-for="item in result_data.hits" class="footer-menu__item "><a
                        :href="item.url ? (item && item.url) : `/${item.slug}`" class="link  v-cloak--hidden">{{
                    item.name }}</a></li>
            </template>
        </ul>
    </el-collapse-item>
</template>

<script>
    import AbstractResult from './AbstractResult'
    import {pathJoin} from "../../utils";

    export default {
        name: "FooterResult",
        extends: AbstractResult,
        props: {
            result_name_prop: {
                required: true,
                type: String
            },
            es_format_prop: {
                required: false,
                default: true
            },
            label: {
                required: true,
                type: String
            },
            mobile: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            all_sports() {
                return this.$store.state.filter_store.all_sports;
            },
            header_label() {
                return this.country ? `Top ${this.label} in ${this.country}` : `Top ${this.label}`
            },
            show_all_label() {
                return `Show all ${this.label}`
            },
            country() {
                return this.$store.state.filter_store.country;
            },
            baseChannelUrl() {
                return pathJoin([window.urls.channelList, this.country])
            }
        },
        methods: {
            getSports() {
                return this.all_sports.filter((element) => {
                    return this.result_data.hits.includes(element.slug);
                }).sort((a, b) => {
                    if (this.result_data.hits.indexOf(a.slug) > this.result_data.hits.indexOf(b.slug)) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            },
            getSportUrl(sport) {
                let country = this.$store.state.filter_store.search_data.top_sports_footer.filter.hasOwnProperty('country_slug') ? this.$store.state.filter_store.search_data.top_sports_footer.filter.country_slug : '';
                return pathJoin([window.urls.channelList, country, sport])
            },
        },
        data() {
            return {
                result_name: this.result_name_prop,
                es_format: this.es_format_prop
            }
        }
    }
</script>