<template>
    <div>
        <div class="row">
            <div class="section-header section-header--channel-results col-16">
                <h3>Channels</h3>
                <div class="align-items-center justify-content-end d-flex">
                    <div class="small-text count-text">{{ (result_data && result_data.total) ? result_data.total.value : 0 }} channel results</div>
                    <button @click="loadAll"
                            :class="{'button--inverse-secondary-hover': isExpanded}"
                            class="button button--inverse-secondary button--small" v-text="isExpanded ? 'Hide all' : 'Explore all'"></button>
                </div>
            </div>
        </div>
        <div class="channel-section-content">
            <div class="row">
                <div class="col-16">
                    <div class="row">
                        <div v-for="item in result_data.hits" class="channel-section-content__item-wrapper">
                            <a class="channel-section-item link" :href="goToChannel(item.slug)">
                                <div class="channel-section-item__image">
                                    <img class="img-fallback" :src="item.logo" :alt="item.name">
                                </div>
                                <div class="channel-section-item__description">
                                    <h5>{{ item.name }}</h5>
                                    <div class="channel-section-item__info">
                                        <div v-if="item.country" class="channel-section-item__logo"><img
                                                :src="item.country.flag" :alt="item.country.code"><span>{{ item.country.name }}</span>
                                        </div>
                                        <span class="channel-section-item__num"></span>
                                        <i class="fas fa-eye"></i><span
                                            v-text="get_number_formatted(item.total_views)"></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="result_data.loading" class="col-16 text-center"><h6>Loading...</h6></div>
            <button v-else-if="showLoadMore" @click="loadMore" class="link channel-section-content__load">Load more
                channels <i class="sport-chevron-circle-right"></i></button>
        </div>
    </div>
</template>

<script>
    import AbstractResult from './AbstractResult'

    export default {
        name: "ChannelsResult",
        extends: AbstractResult,
        data() {
            return {
                result_name: 'channel'
            }
        }
    }
</script>