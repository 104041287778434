<template>
</template>

<script>
    import numeral from 'numeral';
    import moment from 'moment';
    import {pathJoin} from './../../utils';
    import EventBus from './../../event_bus';

    export default {
        name: "AbstractResult",
        data() {
            return {
                result_name: null,
                isExpanded: false,
                load_step: 3,
                oldSize: null,
                es_format: true
            }
        },
        watch: {
            'result_data.loading': {
                handler(newVal) {
                    if(!newVal) {
                        EventBus.$emit('sendHeightMessage');
                    }
                }
            }
        },
        computed: {
            search_data() {
                return this.$store.state.filter_store.search_data
            },
            result_data() {
                if (this.$store.state.filter_store.result.hasOwnProperty(this.result_name)) {
                    let result = {
                        ...this.$store.state.filter_store.result[this.result_name],
                    };
                    if (this.$store.state.filter_store.result[this.result_name].hasOwnProperty('hits')) {
                        if (this.es_format) {
                            result.hits = this.$store.state.filter_store.result[this.result_name].hits.map(item => item['_source'])
                        } else {
                            result.hits = this.$store.state.filter_store.result[this.result_name].hits
                        }
                    }
                    return result;
                }
            },
            showLoadMore() {
                return !this.result_data.loading && this.result_data.hits.length < this.result_data.total.value
            },
            views() {
                if (this.result_data.hasOwnProperty('aggregations') && this.result_data.aggregations.hasOwnProperty('by_id')) {
                    return this.result_data.aggregations.by_id.buckets.reduce(function (map, obj) {
                        map[obj.key] = obj.views.total.value;
                        return map;
                    }, {});
                }
                return []
            }
        },
        methods: {
            loadMore() {
                let size = this.result_data.hits.length + this.load_step;
                let from = this.result_data.hits.length;
                this.$store.dispatch('filter_store/loadMore', {[this.result_name]: {size: size, from: from}})
            },
            loadAll() {
                this.isExpanded = !this.isExpanded;
                let from = this.result_data.hits.length;
                let size = 1000;
                if (this.isExpanded) {
                    this.oldSize = this.result_data.hits.length;
                    this.$store.dispatch('filter_store/loadMore', {[this.result_name]: {size: size, from: from}})
                } else {
                    size = this.oldSize;
                    this.$store.dispatch('filter_store/setFilter', {[this.result_name]: {size: size, from: from}})
                }
            },
            get_number_formatted(number) {
                return numeral(number).format('0 a')
            },
            get_publish_date(iso_date) {
                let date = Date.parse(iso_date);
                let difference = moment(date).diff(new Date(), 'days');
                if (difference >= 7) {
                    return moment(date).format(date, 'MMM D, YYYY at H:mm A')
                } else {
                    return moment(date).fromNow();
                }

            },
            goToChannel(channel, league, game, video) {
                return pathJoin(["/", channel, league, game, video])
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>