<template>
    <div>
      <el-tabs type="card" @tab-click="handleClick" v-model="tab">
        <template v-for="(cam, index) in cams">
            <el-tab-pane v-if="cam.isLive && cam.liveId" :name="'cam'+cam.id+'live'">
              <div slot="label" class="d-flex multicam-item">
                <div class="cam-tab-link">
                  <div class="cam-label" v-text="'CAM '+(index+1)"></div>
                  <img src="/static/images/livetab.svg" height="40px" style="margin-top: 5px">
                </div>
              </div>
            </el-tab-pane>
        </template>
        <template v-for="(cam, index) in cams">
            <el-tab-pane :name="'cam'+cam.id+'highlights'">
              <div slot="label" class="d-flex multicam-item">
                <div class="cam-tab-link"><div class="cam-label" v-text="'CAM '+(index+1)"></div>
                  <img src="/static/images/highlightstab.svg" height="40px" style="margin-top: 5px">
                </div>
              </div>
            </el-tab-pane>
        </template>
      </el-tabs>
      <div v-show="$apollo.queries.allVideos.loading || loading" class="video-player--empty row no-gutters">
          <h4>Loading...</h4>
      </div>
      <div v-show="!$apollo.queries.allVideos.loading && allVideos.length == 0 && !is_livestream" class="video-player--empty row no-gutters"><h4>No videos...</h4></div>
      <div v-show="is_livestream && !$apollo.queries.allVideos.loading" class="video-player video-player-live row no-gutters">
        <div class="col-lg-16">
            <a v-if="branding && !adData.adPlaying" :href="branding.url" target="_blank" @click="trackWatermarkClick">
                <img :src="branding.image_url" alt="Watermark" class="watermark">
            </a>
            <video ref="videoPlayerLive" oncontextmenu="return false;" playsinline id="SV365-player-live" class="video-js vjs-default-skin" width="100%" controls v-cloak></video>
            <div ref="bottompanel">
                <div v-show="game" class="video-info">
                    <div class="match-results match-results--big" v-if="game">
                        <div v-if="simple">
                            <div class="playlist__type"
                                 :class="{ 'playlist__type--action' : currentVideo.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_1goal', 'playlist__type--special' : currentVideo.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_3special'}"><span
                                    class="minute">{{ currentVideo.minute }}'</span><span
                                    class="state">{{ currentVideo.bookmarkDefinition.name }} {{ currentVideo.currentScore }}</span>
                            </div>
                        </div>
                        <div class="match-results__team match-results__team--home">
                            <span class="hidden-xs match-results__team-name">{{ game.homeTeam.name }}</span>
                            <img class="img-fallback" :src="getTeamLogo(game.homeTeam)"
                                 :alt="game.homeTeam.name">
                        </div>
                        <div class="team match-results__state"><span>{{ gameDuplicate.homeGoals }}</span> :
                            <span>{{ gameDuplicate.awayGoals }}</span></div>
                        <div class="team match-results__team team match-results__team--away">
                            <img class="img-fallback" :src="getTeamLogo(game.awayTeam)"
                                 :alt="game.awayTeam.name">
                            <span class="hidden-xs team match-results__team-name">{{ game.awayTeam.name }}</span>
                        </div>
                    </div>
                    <div class="row no-gutters video-info__footer" ref="videoFooter">
                        <template v-if="game">
                            <div class="col-md-8 col-16">
                                <h4 class="visible-xs match-results__team-name">{{ game.homeTeam.name
                                    }} VS. {{ game.awayTeam.name }}</h4>
                                <div class="video-info__text">
                                    <div class="channel-detail-league"><i class="sport-icons8-trophy"></i><span>{{ game.league.name }}</span>
                                    </div>
                                    <div class="dot-div hidden-xs"></div>
                                    <div class="video-info__date">{{ game.created|format_date("DD.MM.YYYY - HH:mm")
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 col-16">
                                <input ref="copyvalue" type="text" readonly :value="currentUrl"
                                       style="position: absolute; left: -99999px;">
                                <div class="video-info__share">
                                    <el-tooltip v-if="donations" class="item" effect="dark" content="Donate to channel" placement="top">
                                        <button v-if="donations" @click="$root.$emit('show-donations')" class="video-info__action">
                                            Donate
                                        </button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="Copy link" placement="top">
                                        <button @click="copyToClipboard" class="video-info__action"><i
                                                class="far fa-copy"></i>
                                        </button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="Embed video" placement="top">
                                        <button @click="embedVisible=true" class="video-info__action"><i
                                                class="fas fa-code"></i>
                                        </button>
                                    </el-tooltip>
                                    <el-tooltip class="item hidden-lg hidden-xs hidden-sm" effect="light" placement="top">
                                        <div class="video-info__social-sites" slot="content">
                                        <custom-social-sharing network-tag="a"
                                                               :networks="additionalNetworks"
                                                               :url="getShareUrl()"
                                                               :title="game.homeTeam.name + ' vs. ' + game.awayTeam.name + '\n'"
                                                               inline-template>
                                            <div class="social-sharing">
                                                <network network="facebook"
                                                         class="video-info__social video-info__social--facebook">
                                                    <i class="fab fa-facebook-f"></i> SHARE
                                                </network>
                                                <network network="twitter"
                                                         class="video-info__social video-info__social--twitter">
                                                    <i class="fab fa-twitter"></i> TWEET
                                                </network>
                                                <network network="whatsapp"
                                                         class="video-info__social video-info__social--whatsapp">
                                                    <i class="fab fa-whatsapp"></i> SEND
                                                </network>
                                                <network network="messenger"
                                                         class="video-info__social video-info__social--messenger hidden-md">
                                                    <i class="fab fa-facebook-messenger"></i> SEND
                                                </network>
                                            </div>
                                        </custom-social-sharing>
                                    </div>
                                        <button class="video-info__action"><i
                                                class="fas fa-share-alt"></i>
                                        </button>
                                    </el-tooltip>
                                    <button @click="nativeShare" class="video-info__action hidden-md hidden-lg"><i
                                                class="fas fa-share-alt"></i>
                                        </button>
                                    <div class="video-info__social-sites hidden-sm hidden-md hidden-xs">
                                        <custom-social-sharing network-tag="a"
                                                               :networks="additionalNetworks"
                                                               :url="getShareUrl()"
                                                               :title="game.homeTeam.name + ' vs. ' + game.awayTeam.name + '\n'"
                                                               inline-template>
                                            <div class="social-sharing">
                                                <network network="facebook"
                                                         class="video-info__social video-info__social--facebook">
                                                    <i class="fab fa-facebook-f"></i> SHARE
                                                </network>
                                                <network network="twitter"
                                                         class="video-info__social video-info__social--twitter">
                                                    <i class="fab fa-twitter"></i> TWEET
                                                </network>
                                                <network network="whatsapp"
                                                         class="video-info__social video-info__social--whatsapp">
                                                    <i class="fab fa-whatsapp"></i> SEND
                                                </network>
                                            </div>
                                        </custom-social-sharing>
                                    </div>
                                </div>
                                <!--                                    <div v-show="progress" class="progress-container">-->
                                <!--                                        <el-progress :text-inside="true" :stroke-width="15"-->
                                <!--                                                     :percentage="progress"></el-progress>-->
                                <!--                                    </div>-->
                                <el-dialog
                                        class="embed-dialog"
                                        title="Embed Tags"
                                        :visible.sync="embedVisible"
                                        width="30%">
                                    <div class="text-center">
                                        <p class="embed-dialog__info embed-dialog__info__first">Game</p>
                                        <textarea name="embed" rows="4" class="embed-dialog__textarea" readonly>
                                            {{ iframeGame }}
                                        </textarea>
                                        <p class="embed-dialog__info">Highlight</p>
                                        <textarea name="embed" rows="4" class="embed-dialog__textarea" readonly>
                                            {{ iframeEvent }}
                                        </textarea>
                                        <button @click="embedVisible = false"
                                                class="button button--secondary embed-dialog__button">
                                            Cancel
                                        </button>
                                    </div>
                                </el-dialog>
                            </div>
                        <div v-if="game.assets && game.assets.length > 0" class="col-md-16">
                                    <button class="video-info video-info__social video-info__social--full_download" @click="fullDownload">
                                        <i class="fas fa-download"></i> DOWNLOAD LIVE GAME RECORDING
                                    </button>
                                </div>
                            </template>

                    </div>
                </div>
            </div>
        </div>
        <div id="live-scoreboard" style="display:None">
          <div class="info-overlay info-overlay__scoreboard">
            <div class="board-container" >
              <div class="board-score" v-if="game">{{game.homeTeam.shortname}} {{gameDuplicate.homeGoals}} - {{gameDuplicate.awayGoals}} {{game.awayTeam.shortname}}</div>
              <div class="score-row" v-if="game"><div class="scoreboard-flag" :style="{'background': game.homeTeam.club.scoreboardColor1 ? game.homeTeam.club.scoreboardColor1 : '#1ED360'}"></div><div class="scoreboard-flag" :style="{'background': game.homeTeam.club.scoreboardColor2 ? game.homeTeam.club.scoreboardColor2 : '#FA1F63'}"></div><div class="board-time"><span>{{timer}}</span></div><div class="scoreboard-flag" :style="{'background': game.awayTeam.club.scoreboardColor1  ? game.awayTeam.club.scoreboardColor1 : '#1ED360'}"></div><div class="scoreboard-flag" :style="{'background': game.awayTeam.club.scoreboardColor2  ? game.awayTeam.club.scoreboardColor2 : '#FA1F63'}"></div></div>
            </div>
          </div>
        </div>
        <div id="overlay-banner" class="hidden">
            <div class="info-overlay info-overlay__banner-container">
                <a v-show="!adDisabled" @click="sendOverlayBannerClick" :href="adData.overlayBanner.ad != null ? adData.overlayBanner.ad.url : ''" target="_blank"
                   class="info-overlay__banner"
                   :style="{backgroundImage: `url(${adData.overlayBanner.ad != null ? adData.overlayBanner.ad.image : ''})`}"></a>
            </div>
        </div>
      </div>
      <div v-show="!is_livestream && !$apollo.queries.allVideos.loading && allVideos.length > 0" class="video-player video-player-live row no-gutters">
            <div v-bind:class="[{ 'col-lg-16': simple ,'col-lg-12': !simple }]">
                <a v-if="branding && !adData.adPlaying" :href="branding.url" target="_blank" @click="trackWatermarkClick">
                    <img :src="branding.image_url" alt="Watermark" class="watermark">
                </a>
                <video ref="videoPlayer" oncontextmenu="return false;" playsinline id="SV365-player" class="video-js vjs-default-skin" width="100%" controls v-cloak></video>
                <div ref="bottompanel">
                    <div v-show="game" class="video-info">
                        <div class="match-results match-results--big" v-if="game">
                            <div v-if="simple">
                                <div class="playlist__type"
                                     :class="{ 'playlist__type--action' : currentVideo.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_1goal', 'playlist__type--special' : currentVideo.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_3special'}"><span
                                        class="minute">{{ currentVideo.minute }}'</span><span
                                        class="state">{{ currentVideo.bookmarkDefinition.name }} {{ currentVideo.currentScore }}</span>
                                </div>
                            </div>
                            <div class="match-results__team match-results__team--home">
                                <span class="hidden-xs match-results__team-name">{{ game.homeTeam.name }}</span>
                                <img class="img-fallback" :src="getTeamLogo(game.homeTeam)"
                                     :alt="game.homeTeam.name">
                            </div>
                            <div class="team match-results__state"><span>{{ gameDuplicate.homeGoals }}</span> :
                                <span>{{ gameDuplicate.awayGoals }}</span></div>
                            <div class="team match-results__team team match-results__team--away">
                                <img class="img-fallback" :src="getTeamLogo(game.awayTeam)"
                                     :alt="game.awayTeam.name">
                                <span class="hidden-xs team match-results__team-name">{{ game.awayTeam.name }}</span>
                            </div>
                        </div>
                        <div class="row no-gutters video-info__footer" ref="videoFooter">
                            <template v-if="game">
                                <div class="col-md-8 col-16">
                                    <h4 class="visible-xs match-results__team-name">{{ game.homeTeam.name
                                        }} VS. {{ game.awayTeam.name }}</h4>
                                    <div class="video-info__text">
                                        <div class="channel-detail-league"><i class="sport-icons8-trophy"></i><span>{{ game.league.name }}</span>
                                        </div>
                                        <div class="dot-div hidden-xs"></div>
                                        <div class="video-info__date">{{ game.created|format_date("DD.MM.YYYY - HH:mm")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 col-16">
                                    <input ref="copyvalue" type="text" readonly :value="currentUrl"
                                           style="position: absolute; left: -99999px;">
                                    <div class="video-info__share">
                                        <el-tooltip v-if="donations" class="item" effect="dark" content="Donate to channel" placement="top">
                                            <button v-if="donations" @click="$root.$emit('show-donations')" class="video-info__action">
                                                Donate
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip v-if="download_allowed || is_staff" class="item" effect="dark" content="Download video" placement="top">
                                            <button v-if="download_allowed || is_staff" @click="downloadVideo" class="video-info__action download-video"><i
                                                    class="fas fa-download"></i>
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Copy link" placement="top">
                                            <button @click="copyToClipboard" class="video-info__action"><i
                                                    class="far fa-copy"></i>
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Embed video" placement="top">
                                            <button @click="embedVisible=true" class="video-info__action"><i
                                                    class="fas fa-code"></i>
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip class="item hidden-lg hidden-xs hidden-sm" effect="light" placement="top">
                                            <div class="video-info__social-sites" slot="content">
                                            <custom-social-sharing network-tag="a"
                                                                   :networks="additionalNetworks"
                                                                   :url="getShareUrl()"
                                                                   :title="game.homeTeam.name + ' vs. ' + game.awayTeam.name + '\n'"
                                                                   inline-template>
                                                <div class="social-sharing">
                                                    <network network="facebook"
                                                             class="video-info__social video-info__social--facebook">
                                                        <i class="fab fa-facebook-f"></i> SHARE
                                                    </network>
                                                    <network network="twitter"
                                                             class="video-info__social video-info__social--twitter">
                                                        <i class="fab fa-twitter"></i> TWEET
                                                    </network>
                                                    <network network="whatsapp"
                                                             class="video-info__social video-info__social--whatsapp">
                                                        <i class="fab fa-whatsapp"></i> SEND
                                                    </network>
                                                    <network network="messenger"
                                                             class="video-info__social video-info__social--messenger hidden-md">
                                                        <i class="fab fa-facebook-messenger"></i> SEND
                                                    </network>
                                                </div>
                                            </custom-social-sharing>
                                        </div>
                                            <button class="video-info__action"><i
                                                    class="fas fa-share-alt"></i>
                                            </button>
                                        </el-tooltip>
                                        <button @click="nativeShare" class="video-info__action hidden-md hidden-lg"><i
                                                    class="fas fa-share-alt"></i>
                                            </button>
                                        <div class="video-info__social-sites hidden-sm hidden-md hidden-xs">
                                            <custom-social-sharing network-tag="a"
                                                                   :networks="additionalNetworks"
                                                                   :url="getShareUrl()"
                                                                   :title="game.homeTeam.name + ' vs. ' + game.awayTeam.name + '\n'"
                                                                   inline-template>
                                                <div class="social-sharing">
                                                    <network network="facebook"
                                                             class="video-info__social video-info__social--facebook">
                                                        <i class="fab fa-facebook-f"></i> SHARE
                                                    </network>
                                                    <network network="twitter"
                                                             class="video-info__social video-info__social--twitter">
                                                        <i class="fab fa-twitter"></i> TWEET
                                                    </network>
                                                    <network network="whatsapp"
                                                             class="video-info__social video-info__social--whatsapp">
                                                        <i class="fab fa-whatsapp"></i> SEND
                                                    </network>
                                                </div>
                                            </custom-social-sharing>
                                        </div>
                                    </div>
                                    <!--                                    <div v-show="progress" class="progress-container">-->
                                    <!--                                        <el-progress :text-inside="true" :stroke-width="15"-->
                                    <!--                                                     :percentage="progress"></el-progress>-->
                                    <!--                                    </div>-->
                                    <el-dialog
                                            class="embed-dialog"
                                            title="Embed Tags"
                                            :visible.sync="embedVisible"
                                            width="30%">
                                        <div class="text-center">
                                            <p class="embed-dialog__info embed-dialog__info__first">Game</p>
                                            <textarea name="embed" rows="4" class="embed-dialog__textarea" readonly>
                                                {{ iframeGame }}
                                            </textarea>
                                            <p class="embed-dialog__info">Highlight</p>
                                            <textarea name="embed" rows="4" class="embed-dialog__textarea" readonly>
                                                {{ iframeEvent }}
                                            </textarea>
                                            <button @click="embedVisible = false"
                                                    class="button button--secondary embed-dialog__button">
                                                Cancel
                                            </button>
                                        </div>
                                    </el-dialog>
                                </div>
                                <div v-if="game.assets && game.assets.length > 0" class="col-md-16">
                                    <button class="video-info video-info__social video-info__social--full_download" @click="fullDownload">
                                        <i class="fas fa-download"></i> DOWNLOAD LIVE GAME RECORDING
                                    </button>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <slot></slot>
            <div v-if="!simple" class="col-lg-4" ref="playlist">
                <h4 v-if="filteredVideos.length == 0" class="text-center playlist-wrapper-background">No
                    Videos</h4>
                <div v-else class="playlist-wrapper-hidden">
                    <div class="playlist-wrapper">
                        <div class="playlist el-scrollbar" v-if="is_touchscreen">
                            <div class="playlist-scroll el-scrollbar__wrap el-scrollbar__wrap--hidden-default">
                                <div class="el-scrollbar__view">
                                    <div class="playlist__total">
                                        <div class="playlist__sum">
                                            <i v-if="game.isLive" class="playlist__live"></i>
                                            <b>{{ allVideos.length }} highlights</b>
                                        </div>
                                        <span v-show="game.views > 15" class="channel-detail-views">{{ game.views }} <i
                                                class="fas fa-eye"></i></span>
                                    </div>
                                    <!--                    TYPES:
                                                            CHANCE - only class="playlist__type"
                                                            GOAL - class="playlist__type playlist__type--action"
                                                            SPECIAL - class="playlist__type playlist__type--special"-->
                                    <button class="button button--full-width button--secondary button--show visible-xs visible-sm playlist__button-mobile"
                                            @click="showPlaylist = !showPlaylist">
                                        <span :class="{ 'hidden' : !showPlaylist }">hide highlights</span><i
                                            class="fas fa-caret-down button-icon" :class="{ 'show' : !showPlaylist }"></i>
                                        <span :class="{ 'hidden' : showPlaylist }">show highlights</span><i
                                            class="fas fa-caret-up button-icon" :class="{ 'show' : showPlaylist }"></i>
                                    </button>
                                    <div class="playlist__total playlist__total--mobile">
                                        <div class="playlist__sum">
                                            <i v-if="game.isLive" class="playlist__live"></i>
                                            <b>{{ allVideos.length }} highlights</b>
                                        </div>
                                        <span v-show="game.views > 15" class="channel-detail-views">{{ game.views }} <i
                                                class="fas fa-eye"></i></span>
                                    </div>
                                    <el-collapse-transition>
                                        <div v-show="showPlaylist">
                                            <ul class="playlist__list">
                                                <li v-for="(video, index) in filteredVideos" class="playlist__item"
                                                    :class="{'playlist__item--active': video.id == currentVideo.id}">
                                                    <a @click="goToVideo(index)">
                                                        <div class="playlist__type"
                                                             :class="{ 'playlist__type--action' : video.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_1goal', 'playlist__type--special' : video.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_3special'}"><span
                                                                class="minute">{{ video.minute }}'</span><span
                                                                class="state">{{ video.bookmarkDefinition.name }} {{ video.currentScore }}</span>
                                                        </div>
                                                        <div v-if="video.team" class="playlist__team">{{ video.team.name }}
                                                        </div>
                                                        <span v-show="show_highlight_views || is_staff" class="channel-detail-views">{{ video.views }} <i
                                                                class="fas fa-eye"></i></span>
                                                        <i class="sport-play-button playlist__hover-icon"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a v-if="maxFilteredVideos < allVideos.length"
                                               class="link visible-sm visible-xs playlist-wrapper__load"
                                               @click="loadMoreVideos"><span>Load more </span><i
                                                    class="sport-chevron-circle-right"></i></a>
                                        </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                        </div>
                        <el-scrollbar wrap-class="playlist-scroll" class="playlist" v-else>
                            <div class="playlist__total">
                                <div class="playlist__sum">
                                    <i v-if="game.isLive" class="playlist__live"></i>
                                    <b>{{ allVideos.length }} highlights</b>
                                </div>
                                <span v-show="game.views > 15" class="channel-detail-views">{{ game.views }} <i
                                        class="fas fa-eye"></i></span>
                            </div>
                            <!--                    TYPES:
                                                    CHANCE - only class="playlist__type"
                                                    GOAL - class="playlist__type playlist__type--action"
                                                    SPECIAL - class="playlist__type playlist__type--special"-->
                            <button class="button button--full-width button--secondary button--show visible-xs visible-sm playlist__button-mobile"
                                    @click="showPlaylist = !showPlaylist">
                                <span :class="{ 'hidden' : !showPlaylist }">hide highlights</span><i
                                    class="fas fa-caret-down button-icon" :class="{ 'show' : !showPlaylist }"></i>
                                <span :class="{ 'hidden' : showPlaylist }">show highlights</span><i
                                    class="fas fa-caret-up button-icon" :class="{ 'show' : showPlaylist }"></i>
                            </button>
                            <div class="playlist__total playlist__total--mobile">
                                <div class="playlist__sum">
                                    <i v-if="game.isLive" class="playlist__live"></i>
                                    <b>{{ allVideos.length }} highlights</b>
                                </div>
                                <span v-show="game.views > 15" class="channel-detail-views">{{ game.views }} <i
                                        class="fas fa-eye"></i></span>
                            </div>
                            <el-collapse-transition>
                                <div v-show="showPlaylist">
                                    <ul class="playlist__list">
                                        <li v-for="(video, index) in filteredVideos" class="playlist__item"
                                            :class="{'playlist__item--active': video.id == currentVideo.id}">
                                            <a @click="goToVideo(index)">
                                                <div class="playlist__type"
                                                     :class="{ 'playlist__type--action' : video.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_1goal', 'playlist__type--special' : video.bookmarkDefinition.bookmarkType.toLowerCase() == 'a_3special'}"><span
                                                        class="minute">{{ video.minute }}'</span><span
                                                        class="state">{{ video.bookmarkDefinition.name }} {{ video.currentScore }}</span>
                                                </div>
                                                <div v-if="video.team" class="playlist__team">{{ video.team.name }}
                                                </div>
                                                <span v-show="show_highlight_views || is_staff" class="channel-detail-views">{{ video.views }} <i
                                                        class="fas fa-eye"></i></span>
                                                <i class="sport-play-button playlist__hover-icon"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <a v-if="maxFilteredVideos < allVideos.length"
                                       class="link visible-sm visible-xs playlist-wrapper__load"
                                       @click="loadMoreVideos"><span>Load more </span><i
                                            class="sport-chevron-circle-right"></i></a>
                                </div>
                            </el-collapse-transition>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
            <div id="info-overlay" class="hidden">
                <div>
                    <a v-show="!adDisabled" @click="sendPostrollClick" :href="adData.image.ad != null ? adData.image.ad.url : ''" target="_blank"
                       class="info-overlay"
                       :style="{backgroundImage: `url(${adData.image.ad != null ? adData.image.ad.image : ''})`}"></a>
                </div>
            </div>
            <div id="banner-template" class="hidden">
                <div v-if="banner && banner.image" class="banner" :style="{backgroundImage: `url(${banner.image})`}">
                    <a v-if="banner.url" :href="banner.url" target="_blank" id="banner-link">
                        <img :src="banner.image" alt="banner">
                    </a>
                    <img v-else :src="banner.image" alt="banner">
                </div>
            </div>
        </div>
      <!-- The Modal -->
      <div class="modal fade show z-4" id="modalWatchTimeExceeded" v-if="watchTimeDepleted" style="display: block; top: 40%">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <!-- Modal body -->
                  <div class="modal-body">
                       <h4>Watch time depleted</h4>
                    <div class="mt-5 ml-5 mr-5">
                      <p>The LIVE watching time of this channel is depleted. The owner of this channel '{{ game.league.channel.name }}' must update his subscription.</p>
                    </div>
                  </div>
                  <!-- Modal footer -->
                  <div class="modal-footer">
                      <button type="submit" class="el-button el-button--primary" @click="closeWatchTimeModal()"
                              data-dismiss="modal">Watch highlights</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal fade show z-4 video-detail-modal" id="modalDownload" v-if="showDownload">
          <div class="modal-dialog modal-md" style="margin-top: 15%">
              <div class="modal-content">
                  <!-- Modal body -->
                  <div class="modal-body">
                       <h4>DOWNLOAD LIVE GAME RECORDING</h4>
                    <div class="mt-5 ml-5 mr-5">
                      <a v-for="(asset, index) in this.game.assets" :href="asset">
                        <button class="video-info video-info__social video-info__social--full_download">
                                       <i class="fas fa-download"></i> DOWNLOAD PART {{index}}
                           </button>
                        </a>
                </div>
                  </div>
                  <!-- Modal footer -->
                  <div class="modal-footer">
                      <button type="submit" class="el-button el-button--primary" @click="showDownload = false"
                              data-dismiss="modal">Close</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
    import videojs from "video.js";
    import "videojs-mux";
    import postscribe from 'postscribe';

    require('videojs-contrib-ads');

    let ima = require('videojs-ima/dist/videojs.ima');
    videojs.registerPlugin('ima', ima.init);

    import {getOriginalId, pathJoin} from "../../utils";
    import Vue from 'vue'
    import sample from 'lodash/sample'
    import axios from 'axios'
    import gql from 'graphql-tag'
    import SlowMotionButton from './controll/slowMotionButton'
    import LoopButton from './controll/loopButton'
    import ClubInfoBig from './controll/clubInfoBig'
    import Navigation from "./controll/navigation"
    import NavigationNext from "./controll/navigationNext"
    import NavigationPrev from "./controll/navigationPrev"
    import SettingsMenuItem from "./controll/settingsMenuItem"
    import GoalsOnlyMenuItem from "./controll/goalsOnlyMenuItem"
    import SettingsMenu from "./controll/settingsMenu"
    import PaintOverlay from "./controll/paintOverlay"
    import PaintButton from "./controll/paintButton"
    import SocialSharing from 'vue-social-sharing';
    import InfoOverlay from "./controll/infoOverlay"
    import OverlayBanner from "./controll/overlayBanner"
    import LiveScoreboard from "./controll/liveScoreboard"
    import EventBus from './../../event_bus';

    function updateLivestreamMutation() {
      return gql`
        mutation UpdateLivestream($liveId: Int!, $watchTime: Int!){
          updateLivestream (id: $liveId, watchTime: $watchTime){
            livestream {
              watchTime
            }
          }
        }
      `
    }

    function getFilterItemsQuery() {
        return gql`
        query Videos($gameSlug: String!, $parentSlug: String!) {
           campaigns(game: $gameSlug){
            edges{
              node{
                id
                priority
                banner{
                  id
                  image
                  html
                  url
                  maxWidth
                  height
                }
                prerollVideo{
                  id
                  video
                  googleUrl
                  url
                  skipCounter
                  startIndex
                },
                postrollImage{
                  id
                  image
                  url
                  skipCounter
                  startIndex
                },
                overlayBanner{
                  id
                  image
                  url
                  duration
                  frequency
                }
              }
            }
          },
          allVideos(game_Slug: $gameSlug) {
            edges {
              node {
                id
                slug
                currentScore
                minute
                views
                url
                embedUrl
                team{
                  name
                }
                bookmarkDefinition{
                  name
                  bookmarkType
                }
                video{
                  id
                  amazonFile
                  urls{
                    edges{
                      node{
                        id
                        url
                        type
                        brandingId
                      }
                    }
                }
                }
              }
            }
          }
          allGames(slug: $gameSlug) {
            edges {
              node {
                id
                slug
                name
                awayGoals
                homeGoals
                views
                created
                liveCreated
                liveId
                isLive
                remainingWatchTime
                url
                livePlaybackUrl
                breakDuration
                isPaused
                assets
                awayTeam {
                  name
                  shortname
                  club {
                    logo
                    logoUrl
                    scoreboardColor1
                    scoreboardColor2
                  }
                }
                homeTeam {
                  name
                  shortname
                  club {
                    logo
                    logoUrl
                    scoreboardColor1
                    scoreboardColor2
                  }
                }
                league {
                  name
                  defaultBannerImage
                  logo
                  channel{
                    slug
                    name
                  }
                }
                parent {
                  id
                  slug
                  url
                  liveId
                  isLive
                }
              }
            }
          }
          cams(parent_Slug: $parentSlug) {
            edges {
              node {
                id
                slug
                name
                awayGoals
                homeGoals
                views
                created
                liveCreated
                liveId
                isLive
                remainingWatchTime
                url
                livePlaybackUrl
                breakDuration
                isPaused
                awayTeam {
                  name
                  shortname
                  club {
                    logo
                    logoUrl
                    scoreboardColor1
                    scoreboardColor2
                  }
                }
                homeTeam {
                  name
                  shortname
                  club {
                    logo
                    logoUrl
                    scoreboardColor1
                    scoreboardColor2
                  }
                }
                league {
                  name
                  defaultBannerImage
                  logo
                  channel{
                    slug
                    name
                  }
                }
                parent {
                  id
                  slug
                  url
                  liveId
                  isLive
                }
              }
            }
          }
        }
        `
    }


    var filters = {
        all: function (videos, maxNumber) {
            return videos.slice(0, maxNumber)
        },
        goals: function (videos, maxNumber) {
            return videos.filter(function (video) {
                return video.bookmarkDefinition.bookmarkType.toLowerCase() === "a_1goal";
            }).slice(0, maxNumber)
        }
    };

    export default {
        name: "VideoPlayerMulticam",
        props: {
            autoplay: {
                type: Boolean,
                default: true
            },
            branding: {
                type: Object,
                default: null
            },
            authenticated: {
                type: Boolean,
                default: false
            },
            show_highlight_views: {
                type: Boolean,
                default: false
            },
            simple: {
                type: Boolean,
                default: false
            },
            embed: {
                type: Boolean,
                default: false
            },
            donations: {
                type: Boolean,
                default: false
            },
            download_allowed: {
                type: Boolean,
                default: false
            },
            isLivestream: {
                type: Boolean,
                default: false
            },
            is_staff: {
                type: Boolean,
                default: false
            },
            embedGame: {
                type: Boolean,
                default: false
            },
            gameSlug: {
                type: String,
                default: null
            },
            muxDataKey: {
                type: String,
                default: null
            },
            parentSlug: {
              type: String,
                default: null
            },
            videoSlug: {
                type: String,
                default: null
            },
            overlayTime: {
                type: Number,
                default: 2000
            },
            playerId: {
                type: String,
                default: 'SV365-player'
            },
            header: {
                type: Object,
                required: false
            },
            adDisabled: {
                type: Boolean,
                default: false
            },
            shareUrl: {
                type: String,
                default: null
            },
        },
        components: {
            SlowMotionButton
        },
        data() {
            return {
                additionalNetworks: {
                  "messenger": {
                    "sharer": "fb-messenger://share/?link=@url",
                    "type": "popup"
                  }
                },
                timerRetries: 0,
                loading: false,
                watchTimeDepleted: false,
                watchTime: 0,
                watchTimeConstant: 20 + Math.floor(Math.random() * 10),
                liveScoreboard: null,
                timer: null,
                baseTime: 0,
                showDownload: false,
                savedCurrentTime: 0,
                firstRun: true,
                is_livestream: this.isLivestream,
                progress: 0,
                iOS: false,
                allVideos: [],
                cams: [],
                isChild: this.parentSlug && this.parentSlug !== this.gameSlug,
                game: null,
                gameDuplicate: null,
                currentVideo: null,
                currentVideoIndex: 0,
                player: null,
                playerLive: null,
                tab: null,
                playerOptions: {
                    aspectRatio: "16:9"
                },
                volume: 1,
                filter: 'all',
                customComponent: null,
                embedVisible: false,
                slowMotionButton: null,
                paintOverlay: null,
                overlayBanner: null,
                overlayBannerInterval: null,
                refetchInterval: null,
                paintButton: null,
                loopButton: null,
                clubInfoBig: null,
                navigationNext: null,
                navigationPrev: null,
                infoOverlay: null,
                headerLogo: null,
                linkOverlay: null,
                settingsMenu: null,
                isLoop: false,
                slowMotionRate: 0.5,
                video_info: null,
                showPlaylist: true,
                overlayTimeout: null,
                imaOptions: {
                    id: this.playerId,
                    adTagUrl: ''
                },
                adData: {
                    adPlaying: false,
                    isAdblock: false,
                    adInit: false,
                    banner: {
                        allAdWithPriority: []
                    },
                    video: {
                        allAdWithPriority: [],
                        ad: null,
                        startIndex: null,
                        skipCounter: null,
                        isSelfHosted: false,
                        isGoogle: false,
                    },
                    image: {
                        allAdWithPriority: [],
                        ad: null,
                        startIndex: null,
                        skipCounter: null,
                        isSelfHosted: false,
                        isGoogle: false,
                    },
                    overlayBanner: {
                      allAdWithPriority: [],
                      ad: null,
                    },
                    numViewedVideos: 1
                },
                maxFilteredVideos: 500,
                current_view: 'desktop',
                viewSend: false,
                filterChanged: false,
                elementsSetUp: false,
                liveElementsSetUp: false,
                currentUrl: '',
                banner: null,
                bannerInitialized: false,
                is_touchscreen: false,
                goToVideoQueuedId: null,
            };
        },
        watch: {
            embedVisible(newVal) {
                if (newVal === true) {
                    this.trackEvent('click', 'embed', this.currentVideo.url, {
                        'game': this.game.name,
                        'event_id': getOriginalId(this.currentVideo.id),
                    });
                }
            },
            game() {
                if (this.is_livestream && this.tab.endsWith("live")) {
                  if (this.game.remainingWatchTime != null && this.game.remainingWatchTime <= 0) {
                    this.watchTimeDepleted = true
                    this.setIntro()
                  } else {
                    this.watchTimeDepleted = false
                    if (this.playerLive && this.playerLive.src() === null) {
                      this.playerLive.src(this.game.livePlaybackUrl)
                    }
                  }
                }

                if (this.$mq !== 'sm' && !this.embed) {
                    this.trackEvent('show', 'headerImage', this.header.image_url, {
                        'ad_id': this.header.id,
                        'game': this.game.name,
                        'game_id': getOriginalId(this.game.id)
                    });
                }
                this.banner = sample(this.adData.banner.allAdWithPriority);
                if(this.banner) {
                    Vue.nextTick(() => {
                        this.initBanner();
                    });
                }
                if (window.location.href.indexOf("#highlights") > -1) {
                  this.tab = this.tab.replace("live", "highlights")
                  this.handleClick({name: this.tab});
                }
                else if (this.is_livestream === true){
                  this.tab = this.tab.replace("highlights", "live")
                  let url = window.location.href
                  window.location.href = url.substring(0, url.indexOf("#")) + '#live'
                }
                if (this.is_livestream) {
                  Vue.nextTick(() => {
                        this.playerInitialize(true)
                        this.playerLive.muted(true);
                        this.playerLive.play()
                  })
                }
                else if (!this.game.isLive && this.playerLive !== null){
                  this.playerLive.src({
                        src: '/static/videos/outro.mp4',
                        type: 'video/mp4'
                      });
                  this.playerLive.loop(true);
                  this.playerLive.play();
                }
            },
            currentVideoIndex() {
                if (this.player) {
                    if (this.isLast) {
                        this.player.getChild('NavigationNext').hide()
                    }
                    if (this.isFirst) {
                        this.player.getChild('NavigationPrev').hide()
                    }
                }
            },
            currentVideo: {
                handler: function (newVal) {
                    if (this.player) {
                        this.viewSend = false;
                        this.player.src(this.currentVideoUrl);
                        this.sendBannerShown();
                        this.player.play()
                    }
                },
                deep: true
            },
            filteredVideos(newVal) {
                if (this.currentVideoIndex >= this.filteredVideos.length){
                  this.currentVideoIndex = Math.max(this.filteredVideos.length - 1, 0)
                }
                if (this.filteredVideos.length > 0 && this.currentVideoIndex < this.filteredVideos.length && this.currentVideoIndex >= 0) {
                    if (this.videoSlug) {
                        let new_index = newVal.map(function (e) {
                            return e.slug;
                        }).indexOf(this.videoSlug);
                        if (new_index != -1) {
                            this.currentVideoIndex = new_index
                        } else {
                            let indx_out_of_filtvids = this.allVideos.map(function (e) {
                                return e.slug;
                            }).indexOf(this.videoSlug);
                            if (indx_out_of_filtvids >= this.maxFilteredVideos) {
                                this.currentVideoIndex = indx_out_of_filtvids;
                                this.maxFilteredVideos = this.currentVideoIndex + 1;
                            }
                        }
                    }
                    this.currentVideo = this.filteredVideos[this.currentVideoIndex];
                    // first ad setup
                    this.setNextAd('video');
                    this.setNextAd('image');
                    if (this.player === null) {
                      Vue.nextTick(() => {
                        this.playerInitialize();
                      });
                    }
                }
                if (this.goToVideoQueuedId) {
                  this.$nextTick(() => {
                    this.goToVideoById(this.goToVideoQueuedId);
                    this.goToVideoQueuedId = null;
                  });
                }
            }
        },
        methods: {
          fullDownload(){
            if (window.hasOwnProperty('gtag')) {
                  window.gtag('event', 'click_download_full_game',
                    {
                        device: window.navigator.userAgent
                    });
              }
            if (this.game.assets.length === 1){
              var link = document.createElement("a");
              link.href = this.game.assets[0];
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
            else if (this.game.assets.length > 1){
              this.showDownload = true
            }
          },
          updateWatchTime(hardWatchTime=null) {
            if(hardWatchTime !== null){
              this.$apollo.mutate({
                  mutation: updateLivestreamMutation(),
                  variables: {
                    liveId: this.game.liveId,
                    watchTime: hardWatchTime
                  }
                })
            }
            else if (this.playerLive && this.playerLive.src() === this.game.livePlaybackUrl) {
              if (!this.watchTimeDepleted) {
                if (this.playerLive.currentTime() > this.watchTime){
                  let timeToSend = this.playerLive.currentTime() - this.watchTime
                  this.$apollo.mutate({
                    mutation: updateLivestreamMutation(),
                    variables: {
                      liveId: this.game.liveId,
                      watchTime: timeToSend <= this.watchTimeConstant + 1 ? timeToSend : this.watchTimeConstant
                    }
                  })
                }
                this.watchTime = this.playerLive.currentTime()
              }
            }
            },
            closeWatchTimeModal(){
              window.location.href = this.game.url
            },
            fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s},
            getVideoTime() {
              if (this.playerLive) {
                if (!this.playerLive.paused()) {
                  if (this.playerLive.currentTime() < this.savedCurrentTime) {
                    if (this.game.isPaused) {
                      this.setIntro()
                    }
                  }
                  if (this.playerLive.tech(true).vhs) {
                    let duration = Math.floor((this.baseTime - this.game.breakDuration) + this.playerLive.currentTime())
                    this.timer = duration > 0 ? this.fmtMSS(duration) : '0:00'
                  } else if (this.playerLive.src() === this.game.livePlaybackUrl) {
                    let created = new Date(this.game.liveCreated)
                    let duration = Math.floor(Math.abs(Date.now() - created) / 1000) - this.game.breakDuration
                    this.timer = duration > 0 ? this.fmtMSS(duration) : '0:00'
                  }
                }
                this.savedCurrentTime = this.playerLive.currentTime()
              }
            },
            handleClick(tab, event) {
                this.loading = true
                var i = tab.name.replace(/cam/g, "")
                var livecam = false
                if (tab.name.endsWith('live')){
                  i = i.replace(/live/g, "")
                  livecam = true
                }
                if (tab.name.endsWith('highlights')) {
                  i = i.replace(/highlights/g, "")
                }
                this.game = this.cams.find((el) => el.id === i)
                if (livecam === true) {
                  if (this.player){
                    this.player.pause()
                  }
                  this.is_livestream = true
                  if (this.playerLive === null){
                    this.playerInitialize(true)
                  }
                  else {
                    this.playerLive.src(this.game.livePlaybackUrl);
                    this.playerLive.play()
                  }
                }
                else{
                  if (this.playerLive){
                    this.playerLive.pause()
                  }
                  this.is_livestream = false
                  if (this.player === null){
                    this.playerInitialize()
                  }
                }

              if (tab.name.endsWith("highlights")) {
                if (!window.location.href.endsWith('#highlights')) {
                  let url = window.location.href
                  window.location.href = url.substring(0, url.indexOf("#")) + '#highlights'
                }
              }
              if (tab.name.endsWith("live")) {
                if (!window.location.href.endsWith('#live')) {
                  let url = window.location.href
                  window.location.href = url.substring(0, url.indexOf("#")) + '#live'
                }
              }
              this.loading = false
          },
          nativeShare() {
            if (navigator.share) {
              navigator.share({
                title: this.game.homeTeam.name + ' vs. ' + this.game.awayTeam.name,
                url: this.getShareUrl(),
              })
            }
          },
          sendBannerShown() {
            if (this.banner) {
              this.trackEvent('show', 'banner', this.banner.image || getOriginalId(this.banner.id), {
                'ad_id': getOriginalId(this.banner.id),
                'game': this.game.name,
                'game_id': getOriginalId(this.game.id)
              });
            }
          },
          initBanner() {
            this.sendBannerShown();
            if (!this.bannerInitialized) {
              if (this.banner.html) {
                let url = `${window.location.protocol}//${window.location.host}${window.Urls['common:adform-redirect']()}?game_id=${getOriginalId(this.game.id)}&game_name=${encodeURIComponent(this.game.name)}&ad_id=${getOriginalId(this.banner.id)}`;
                let data = this.banner.html;
                data = data.replace(/href=\s*"([^"]*)/, `href="${url}&redirect=$1`);
                data = data.replace(/src=\s*"([^"]*)/, `src="$1;click=${url}&redirect=`);
                document.querySelectorAll('.banner-wrapper>div').forEach((el) => {
                  postscribe(el, data);
                })
              } else {
                const adEl = this.$el.querySelector('#banner-template');
                document.querySelectorAll('.banner-wrapper>div').forEach((el) => {
                  el.insertAdjacentHTML('afterbegin', adEl.innerHTML);
                })
              }
              this.bannerInitialized = true;
            }
            let banner_link = document.getElementById('banner-link');
            if (banner_link) {
              document.getElementById('banner-link').addEventListener('click', () => {
                this.trackEvent('click', 'banner', this.banner.image_url, {
                  'ad_id': getOriginalId(this.banner.id),
                  'game': this.game.name,
                  'game_id': getOriginalId(this.game.id)
                });
              })
            }
          },
          sendPostrollClick() {
            this.trackEvent('click', 'postroll', this.adData.image.ad.image, {
              'ad_id': getOriginalId(this.adData.image.ad.id),
              'event_id': getOriginalId(this.currentVideo.id),
            });
          },
          sendOverlayBannerClick() {
            this.trackEvent('click', 'overlayBanner', this.adData.overlayBanner.ad.image, {
              'ad_id': getOriginalId(this.adData.overlayBanner.ad.id),
              'game': this.game.name,
              'game_id': getOriginalId(this.game.id)
            });
          },
          getShareUrl() {
            if (this.shareUrl !== null)
              return this.shareUrl
            var share_url = window.location.href;
            this.$root.$on('social_shares_open', (network, url) => {
              this.trackEvent('click', 'share', url, {
                'game': this.game.name,
                'event_id': getOriginalId(this.currentVideo.id),
              });
              this.$root.$emit('social_share_continue', network, url);
            });
            return share_url;
          },
          getBrandedThumbnail(network, event_url) {
            let url = null;
            if (this.branding)
              url = this.$store.state.global_data.urls['video:video-thumbnail'](this.currentVideo.slug, this.branding.id);
            else
              url = this.$store.state.global_data.urls['video:video-thumbnail'](this.currentVideo.slug, null);
            var current_progress = 0,
                step = 0.5;
            var interval = setInterval(() => {
              current_progress += step;
              this.progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
              if (this.progress >= 100) {
                this.progress = 100;
                clearInterval(interval);
              } else if (this.progress >= 70) {
                step = 0.1
              }
            }, 100);
            axios.get(url,
                {
                  onDownloadProgress: (progressEvent) => {
                    this.progress = progressEvent.loaded;
                    clearInterval(interval);
                  },
                }
            )
                .then((response) => {
                  var elems_to_remove = [
                    document.querySelector("meta[property='og:image']"),
                    document.querySelector("meta[property='og:image:url']"),
                    document.querySelector("meta[property='twitter:image']"),
                    document.querySelector("meta[property='og:updated_time']")];
                  var filtered = elems_to_remove.filter(function (el) {
                    return el != null;
                  });
                  filtered.forEach((elem) => elem.remove());
                  var og_image = document.createElement('meta');
                  og_image.setAttribute('property', 'og:image');
                  og_image.content = response.data.url;
                  document.getElementsByTagName('head')[0].appendChild(og_image);
                  var og_image_url = document.createElement('meta');
                  og_image_url.setAttribute('property', 'og:image:url');
                  og_image_url.content = response.data.url;
                  document.getElementsByTagName('head')[0].appendChild(og_image_url);
                  var twitter_image = document.createElement('meta');
                  twitter_image.setAttribute('property', 'twitter:image');
                  twitter_image.content = response.data.url;
                  document.getElementsByTagName('head')[0].appendChild(twitter_image);
                  var og_up_time = document.createElement('meta');
                  og_up_time.setAttribute('property', 'og:updated_time');
                  og_up_time.content = Date.now();
                  document.getElementsByTagName('head')[0].appendChild(og_up_time);
                  let invalidate_url = "https://developers.facebook.com/tools/debug/og/object?q=" + this.getShareUrl() + "?fbrefresh=89127348912";
                  axios.get(invalidate_url);
                  this.$root.$emit('virendered', network, event_url);
                })
                .catch((error) => {
                  this.$toasted.show('Server error occurred. :(', this.toastOptions.error);
                });
          },
          setTimeStamp(url) {
            return url.replace("[TIMESTAMP]", new Date().valueOf());
          },

          setNextAd(type = 'image') {
            let result = sample(this.adData[type].allAdWithPriority);
            if (result != null) {
              if (type !== 'overlayBanner') {
                if (result.hasOwnProperty('googleUrl') && result.googleUrl != null && result.googleUrl != '') {
                  this.adData[type].isGoogle = true;
                } else {
                  this.adData[type].isSelfHosted = true;
                }
                if (this.adData[type].ad == null) {
                  this.adData[type].startIndex = result.startIndex;
                } else {
                  this.adData[type].startIndex = null;
                }
                this.adData[type].skipCounter = result.skipCounter;
              }
              this.adData[type].ad = result;
            }
          },
          loadMoreVideos() {
            this.maxFilteredVideos += 10;
            EventBus.$emit('sendHeightMessage');
          },
          replay() {
            if (this.overlayTimeout) {
              clearTimeout(this.overlayTimeout);
            }
            this.player.play();
          },
          replaySlow() {
            this.player.playbackRate(this.slowMotionRate);
            this.replay();
          },
          nextVideo() {
            if (this.simple) {
              if (this.isLoop) {
                this.goToVideo(this.currentVideoIndex);
              } else {
                return;
              }
            } else {
              let newIndex = this.currentVideoIndex + 1;
              this.goToVideo(newIndex);
            }
          },
          prevVideo() {
            let newIndex = this.currentVideoIndex - 1;
            this.goToVideo(newIndex);
          },
          goToVideo(newIndex, play = true) {
            try {
              if ((this.$mq === 'sm') || (this.$mq === 'md')) {
                if (this.iOS && this.embed) {
                  parent.self.scrollTo(0, 0);
                } else {
                  this.$scrollTo(document.querySelector('.el-tabs'), 500, {'offset': -101});
                }
              }
            } catch (err) {
              console.error(err);
            }

            if ((newIndex == this.currentVideoIndex && !this.filterChanged && !this.simple) || (this.isAd && this.adData.adPlaying)) {
              return;
            }
            let oldCurrentVideoIndex = this.currentVideoIndex;
            if (newIndex < this.filteredVideos.length && newIndex >= 0) {
              this.currentVideoIndex = newIndex;
            } else if (this.filteredVideos.length < this.allVideos.length) {
              this.loadMoreVideos();
              this.currentVideoIndex = newIndex;
            } else if (this.isLoop) {
              if (newIndex < 0) {
                newIndex = this.filteredVideos.length - 1;
              }
              this.currentVideoIndex = newIndex % this.filteredVideos.length;
            }
            this.currentVideo = this.filteredVideos[this.currentVideoIndex];
            if (play && (oldCurrentVideoIndex != this.currentVideoIndex || this.filterChanged || this.simple)) {
              this.adData.numViewedVideos += 1;
              if (this.adData.numViewedVideos == this.adData.image.startIndex)
                this.adData.image.startIndex = null;
              if (this.isAd) {
                if (!this.adData.adPlaying) {
                  if (this.adData.video.isGoogle) {
                    if (!this.adData.adInit) {
                      // Handles the case where the user loads the page, clicks a playlist item
                      // immediately, and never clicks the play button on the player.
                      this.initAdContainer();
                    }

                    let oldSkipCounterVideo = this.adData.video.skipCounter;
                    if (oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1) {
                      this.setNextAd('video');
                    }
                    if (this.adData.video.startIndex != null && this.adData.video.startIndex == this.adData.numViewedVideos) {
                      this.player.ima.setContentWithAdTag(
                          this.currentVideo.url,
                          this.setTimeStamp(this.adData.video.ad.googleUrl),
                          false);
                      this.player.ima.requestAds();
                    } else if (oldSkipCounterVideo != null && (oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1)) {
                      this.player.ima.setContentWithAdTag(
                          this.currentVideo.url,
                          this.setTimeStamp(this.adData.video.ad.googleUrl),
                          false);
                      this.player.ima.requestAds();
                    } else {
                      if (this.adData.video.startIndex == null) {
                        this.adData.video.skipCounter -= 1;
                      }
                    }
                  }
                }
              }
              var url = this.currentVideo.url;
              var splitted_path = window.location.pathname.split("/").filter(Boolean);
              if (splitted_path[0] === "embed-competition" || splitted_path[0] === "embed-club") {
                url = pathJoin([splitted_path[0], splitted_path[1], this.gameSlug, this.currentVideo.slug]);
              } else if (this.embedGame) {
                url = pathJoin(['embed-game', this.gameSlug, this.videoSlug]);
              } else if (this.simple) {
                url = pathJoin(['embed-highlight', this.gameSlug, this.videoSlug]);
              } else if (this.embed) {
                url = this.currentVideo.embedUrl;
              }
              url += window.location.search;
              history.replaceState({}, null, pathJoin([window.location.protocol, url]));
              Vue.nextTick(() => {
                this.player.play();
                this.showNavigation();
              });
              this.filterChanged = false;
            }
            document.getElementsByName('next').forEach(function (item) {
              item.value = window.location.href
            })
          },
          goToVideoById(video_id) {
            if (!this.loading && !this.$apollo.queries.allVideos.loading) {
              let index = this.filteredVideos.findIndex(v => v.id === video_id);
              if (index !== -1) {
                this.goToVideo(index);
                if (this.current_view === 'desktop') {
                  Vue.nextTick(() => {
                    try {
                      let scrollList = document.querySelector('.playlist-scroll');
                      if (scrollList) {
                        scrollList.scrollTo({ top: scrollList.querySelector('.playlist__item--active').offsetTop });
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  });
                }
              } else if (
                this.current_view === 'mobile' &&
                this.filteredVideos.length < this.allVideos.length &&
                this.allVideos.findIndex(v => v.id === video_id) !== -1
              ) {
                this.loadMoreVideos()
                this.goToVideoById(video_id);
              }
            } else {
              this.goToVideoQueuedId = video_id;
            }
          },
          triggerVideoFromComment(video_id, tab_name){
            if (this.tab === tab_name && this.allVideos.findIndex(v => v.id === video_id) === -1) {
              return;
            }

            if (this.tab !== tab_name) {
              let cam_id = tab_name.replace(/cam/g, "").replace(/highlights/g, "");
              if (!this.cams.find((el) => el.id === cam_id)) {
                return;
              }

              this.tab = tab_name;
              this.handleClick({name: tab_name});
            }

            try {
              if (this.$mq === 'lg') {
                if (this.iOS && this.embed) {
                    parent.self.scrollTo(0, 0);
                } else {
                    this.$scrollTo(document.querySelector('.el-tabs'), 500, {'offset': -116});
                }
              }
            } catch (err) {
              console.error(err);
            }

            this.$nextTick(() => {
              this.goToVideoById(video_id);
            });
          },
          getCommentBadgeData() {
            let data = {
                comment_type: null
            };

            if (this.tab.includes('live')) {
                data.comment_type = 'live';
            } else if (this.currentVideo) {
                const video = this.currentVideo;

                data.comment_type = 'highlight';
                data.tab = this.tab;
                data.video_id = video.id;
                data.bookmark_type = video.bookmarkDefinition.bookmarkType.toLowerCase();
                data.minute = video.minute;
                data.state = video.bookmarkDefinition.name + (video.currentScore ? " " + video.currentScore : "");
            }

            return data;
          },
          copyToClipboard() {
            this.$refs.copyvalue.value = window.location.href;
            if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
              // convert to editable with readonly to stop iOS keyboard opening
              this.$refs.copyvalue.contentEditable = true;
              this.$refs.copyvalue.readOnly = true;

              // create a selectable range
              var range = document.createRange();
              range.selectNodeContents(this.$refs.copyvalue);

              // select the range
              var selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
              this.$refs.copyvalue.setSelectionRange(0, 999999);
            } else {
              this.$refs.copyvalue.select();
            }
            document.execCommand("copy");
            this.trackEvent('click', 'copy_link', this.currentVideo.url, {
              'game': this.game.name,
              'event_id': getOriginalId(this.currentVideo.id),
            });
            this.$notify({
              title: 'URL copied to clipboard',
              type: 'success'
            });
          },
          downloadVideo() {
            let link = document.createElement("a");
            var download_url = "/video/video-download/" + this.currentVideo.slug;
            if (this.branding) {
              download_url = download_url + "/" + this.branding.id;
            }
            link.href = download_url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.trackEvent('click', 'download', download_url, {
              'game': this.game.name,
              'event_id': getOriginalId(this.currentVideo.id),
            });
          },
          showImageAdOverlay() {
            this.infoOverlay.show();
            this.adData.adPlaying = true;
            this.overlayTimeout = setTimeout(() => {
              this.infoOverlay.hide();
              this.adData.adPlaying = false;
              this.nextVideo();
              this.adData.image.startIndex = null;
            }, this.overlayTime);
            this.trackEvent('play', 'postroll', this.adData.image.ad.image, {
              'ad_id': getOriginalId(this.adData.image.ad.id),
              'event_id': getOriginalId(this.currentVideo.id),
            });
          },
          showOverlayBanner() {
            this.overlayBanner.show();
            setTimeout(() => {
              this.overlayBanner.hide();
              this.setNextAd('overlayBanner')
            }, this.adData.overlayBanner.ad.duration * 1000);
            this.trackEvent('play', 'overlayBanner', this.adData.overlayBanner.ad.image, {
              'ad_id': getOriginalId(this.adData.overlayBanner.ad.id),
              'game': this.game.name,
              'game_id': getOriginalId(this.game.id)
            });
          },
          trackEvent(action, category, label, additional = null) {
            if (window.hasOwnProperty('gtag')) {
              window.gtag('event', action,
                  {
                    event_category: category,
                    event_label: label,
                    ...additional
                  });
            }
          },
          videoEnded() {
            if (this.adData.image.isSelfHosted) {
              let oldSkipCounterVideo = this.adData.image.skipCounter;
              if ((oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1) && this.adData.image.startIndex == null) {
                this.setNextAd('image');
              }
              if (this.adData.image.startIndex != null && this.adData.image.startIndex == this.adData.numViewedVideos) {
                this.showImageAdOverlay();
              } else if (this.adData.image.startIndex == null && oldSkipCounterVideo != null && (oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1)) {
                this.showImageAdOverlay();
              } else {
                this.nextVideo();
                if (this.adData.image.startIndex == null) {
                  this.adData.image.skipCounter -= 1;
                }
              }
            } else {
              this.nextVideo();
            }
          },
          videoPlay() {
          },
          adEnd() {
            this.adData.adPlaying = false;
            this.adData.video.startIndex = null;
            this.showNavigation();
            if (this.linkOverlay) {
              this.linkOverlay.className = "vast-blocker vast-blocker--hidden";
            }
          },
          adStart() {
            this.adData.adPlaying = true;
            this.clubInfoBig.hide();
            this.navigationNext.hide();
            this.navigationPrev.hide();
            if (this.linkOverlay && this.adData.video.ad.url != null && this.adData.video.ad.url != '') {
              this.linkOverlay.className = "vast-blocker";
              this.linkOverlay.href = this.adData.video.ad.url;
              this.linkOverlay.addEventListener('click', () => {
                this.trackEvent('click', 'preroll', this.adData.video.ad.video, {
                  'ad_id': getOriginalId(this.adData.video.ad.id),
                  'event_id': getOriginalId(this.currentVideo.id),
                });
              })
            }
            this.trackEvent('play', 'preroll', this.adData.video.ad.video, {
              'ad_id': getOriginalId(this.adData.video.ad.id),
              'event_id': getOriginalId(this.currentVideo.id),
            });
          },
          showNavigation() {
            this.navigationNext.show();
            this.navigationPrev.show();
            if (this.isLast && !this.isLoop) {
              this.navigationNext.hide();
            }
            if (this.isFirst && !this.isLoop) {
              this.navigationPrev.hide();
            }
          },
          navigationPrevHandler() {
            this.prevVideo();
          },
          navigationNextHandler() {
            this.nextVideo();
          },
          setLoop() {
            this.isLoop = this.loopButton.isActive();
            this.showNavigation();
          },
          filterVideos(event, data) {
            this.filterChanged = true;
            this.filter = data['filter'];
          },
          fixNativeControls() {
            // Remove controls from the player on iPad to stop native controls from stealing
            // our click
            let contentPlayer = document.getElementById(`${this.playerId}_html5_api`);
            if ((navigator.userAgent.match(/iPad/i) ||
                    navigator.userAgent.match(/Android/i)) &&
                contentPlayer.hasAttribute('controls')) {
              contentPlayer.removeAttribute('controls');
            }
          },
          initAdContainer() {
            if (!this.adData.adInit) {
              this.adData.adInit = true;
              this.player.ima.initializeAdDisplayContainer();
            }
          },
          movePlayerFooterMobile() {
            if (((this.$mq === 'sm') || (this.$mq === 'md')) && !this.simple) {
              this.video_info = this.$refs.videoFooter;
              (this.$refs.playlist).appendChild(this.video_info);
            }
          },
          playStaticVideoAd() {
            this.player.ads.startLinearAdMode();
            // play your linear ad content
            // in this example, we use a static mp4
            this.player.src(this.adData.video.ad.video);
            if ((this.$mq === 'sm') || (this.$mq === 'md')) {
              setTimeout(function () {
                document.querySelector('video').play()
              }, 300);
            }
            // send event when ad is playing to remove loading spinner
            this.player.one('adplaying', () => {
              this.player.trigger('adstart');
              this.player.trigger('ads-ad-started');
            });

            // resume content when all your linear ads have finished
            this.player.one('adended', () => {
              this.player.ads.endLinearAdMode();
            });
          },
          setupStaticVideoAd() {
            this.player.ads({
              postrollTimeout: 0
            }); // initialize videojs-contrib-ads

            this.player.on('contentchanged', () => {
              // in a real plugin, you might fetch your ad inventory here
              this.player.trigger('adsready');
            });

            //create clicable overlay before ad
            this.linkOverlay = window.document.createElement("a");
            this.linkOverlay.className = "vast-blocker vast-blocker--hidden";
            this.linkOverlay.href = "#";
            this.linkOverlay.target = "_blank";
            this.player.el().insertBefore(this.linkOverlay, this.player.controlBar.el());

            this.player.on('readyforpreroll', () => {
              let oldSkipCounterVideo = this.adData.video.skipCounter;
              if (oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1) {
                this.setNextAd('video');
              }
              if (this.adData.video.startIndex != null && this.adData.video.startIndex == this.adData.numViewedVideos) {
                this.playStaticVideoAd();
              } else if (oldSkipCounterVideo != null && (oldSkipCounterVideo == 0 || oldSkipCounterVideo == 1)) {
                this.playStaticVideoAd();
              } else {
                this.player.ads.skipLinearAdMode();
                if (this.adData.video.startIndex == null) {
                  this.adData.video.skipCounter -= 1;
                }
              }
            });
            this.player.trigger('adsready');
          },
          setupVastVideoAd() {
            if (this.adData.video.startIndex != null && this.adData.video.startIndex == 1) {
              this.imaOptions.adTagUrl = this.setTimeStamp(this.adData.video.ad.googleUrl);
            }
            this.player.ima(this.imaOptions);
            this.fixNativeControls();
            let startEvent = 'click';
            if (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/Android/i)) {
              startEvent = 'touchend';
            }

                let wrapperDiv = document.getElementById(this.playerId);
                let self = this;
                wrapperDiv.addEventListener(startEvent, function initAdDisplayContainer() {
                    self.initAdContainer();
                    wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
                });
                this.initAdContainer();
            },
            playerInitialize(live=false) {
                if (live === true){
                  this.playerLive = videojs('SV365-player-live', {
                    aspectRatio: "16:9",
                    plugins: {
                      mux: {
                        debug: false,
                        data: {
                          env_key: this.muxDataKey,
                          player_name: 'Sport.Video player live',
                          video_id: this.game.id,
                          video_title: this.game.name
                        }
                      }
                    }
                });
                  if (this.game.isPaused) {
                    this.setIntro()
                  } else {
                    this.playerLive.src(this.game.livePlaybackUrl);
                  }
                  this.setupElementsLive();
                  this.playerLive.tech(true).on('error', (event) => {
                      this.setIntro()
                  })
                  this.playerLive.tech(true).on('retryplaylist', (event) => {
                    if (!this.game.isLive){
                      this.playerLive.src({
                        src: '/static/videos/outro.mp4',
                        type: 'video/mp4'
                      });
                      this.playerLive.loop(true);
                    } else {
                      this.setIntro()
                    }
                    this.playerLive.play();
                  })
                  this.updateWatchTime(this.watchTimeConstant)
                }
                else {
                  this.player = videojs('SV365-player', this.playerOptions);

              this.player.src(this.currentVideoUrl);
              if (this.isAd) {
                if (this.adData.video.isSelfHosted) {
                  this.setupStaticVideoAd();
                }
                if (this.adData.video.isGoogle || this.adData.image.isGoogle) {
                  this.setupVastVideoAd();
                }
              }
              this.setupEvents();
              this.setupElements();

              this.observer.observe(document.querySelector('#SV365-player'));
            }
          },
          setIntro() {
            if (this.playerLive.src() !== '/static/videos/intro.mp4') {
              this.playerLive.src({
                src: '/static/videos/intro.mp4',
                type: 'video/mp4'
              });
              this.playerLive.play();
              this.playerLive.tech(true).on('ended', (event) => {
                if (this.game.isPaused) {
                  this.setIntro()
                } else {
                  let created = new Date(this.game.liveCreated)
                  this.baseTime = (Math.abs(Date.now() - created) / 1000) - 5
                  this.playerLive.src(this.game.livePlaybackUrl);
                  this.savedCurrentTime = 0
                }
                this.playerLive.play();
              })
            }
          },
          videoPlayerReady() {
            this.player.muted(true);
            if (this.autoplay)
              if (this.tab === 'live') {
                this.playerLive.muted(true);
                this.playerLive.play();
              } else
                this.player.play();
            if (this.simple)
              this.settingsMenu.hide();
          },
          videoTimeUpdate() {
            if (this.player.currentTime() > (this.player.duration() / 2) && !this.viewSend) {
              this.viewSend = true;
              this.trackEvent('play', 'video', getOriginalId(this.currentVideo.id), {
                'game': this.game.name,
                'videourl': this.currentVideo.url
              });
              if (this.branding) {
                this.trackEvent('show', 'watermark', this.branding.image_url, {
                  'ad_id': this.branding.id,
                  'event_id': getOriginalId(this.currentVideo.id),
                  'game': this.game.name,
                  'game_id': getOriginalId(this.game.id)
                })
              }
            }
          },
          trackWatermarkClick() {
            this.trackEvent('click', 'watermark', this.branding.image_url, {
              'ad_id': this.branding.id,
              'event_id': getOriginalId(this.currentVideo.id),
            })
          },
          setupEvents() {
            this.player.on('ended', this.videoEnded);
            this.player.on('play', this.videoPlay);
            this.player.on('timeupdate', this.videoTimeUpdate);
            this.player.on('ready', this.videoPlayerReady);
            this.player.on('adend', this.adEnd);
            this.player.on('adstart', this.adStart);
            this.player.on('showNavigation', this.showNavigation);
            this.player.on('filterVideos', this.filterVideos);
            this.player.on('navigationPrev', this.navigationPrevHandler);
            this.player.on('navigationNext', this.navigationNextHandler);
            this.player.on('setLoop', this.setLoop);
          },
          setupElements() {
            if (this.elementsSetUp === false) {
              this.slowMotionButton = this.player.controlBar.addChild('SlowMotionButton', {
                slowMotionRate: this.slowMotionRate
              });
              this.loopButton = this.player.controlBar.addChild('LoopButton');
              this.settingsMenu = this.player.controlBar.addChild('SettingsMenu');
              this.paintButton = this.player.controlBar.addChild('PaintButton');
              this.paintOverlay = this.player.addChild('PaintOverlay');
              this.navigationNext = this.player.addChild('NavigationNext');
              this.navigationPrev = this.player.addChild('NavigationPrev');
              this.infoOverlay = this.player.addChild('InfoOverlay', {
                overlayTime: this.overlayTime
              });
              this.clubInfoBig = this.player.addChild('ClubInfoBig', {
                awayClub: {
                  name: this.game.awayTeam.name,
                  goals: this.game.awayGoals,
                  logo_url: this.getTeamLogo(this.game.awayTeam)
                },
                homeClub: {
                  name: this.game.homeTeam.name,
                  goals: this.game.homeGoals,
                  logo_url: this.getTeamLogo(this.game.homeTeam)
                }
              });
              this.elementsSetUp = true;
            }
          },
          setupElementsLive() {
            if (this.liveElementsSetUp === false) {
              this.liveScoreboard = this.playerLive.addChild('LiveScoreboard');
              this.overlayBanner = this.playerLive.addChild('OverlayBanner', {
                overlayTime: 0
              });
              this.liveElementsSetUp = true;
            }
          },
          getTeamLogo(team) {
            return (team.club.logo != null && team.club.logo != "") ? team.club.logo : team.club.logoUrl
          },
          scrollToComment() {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('c')) {
              let el = document.getElementById('c' + urlParams.get('c'))
              const headerOffset = 300;
              let elementPosition = el.getBoundingClientRect().top;
              let offsetPosition = elementPosition + window.scrollY - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
              });
            }
          }
        },
      updated() {
        if (this.cams.findIndex(x => x.slug === this.game.slug) === -1) {
          if (this.tab === 'live' && this.cams.findIndex(x => x.isLive && x.liveId !== null) > -1)
            window.location.href = this.cams[this.cams.findIndex(x => x.isLive && x.liveId !== null)].url + '#live'
          window.location.href = this.cams[0].url + '#highlights'
        }
      },
      computed: {
        iframeGame() {
          if (this.currentVideo)
            return `<script type="text/javascript">function sbIframeInit(){const e=document.getElementById("sbIframe${this.gameSlug}");window.addEventListener("message",function(t){"string"!=typeof t.data&&(e.height=t.data)},!1),e.contentWindow.postMessage("message","https://sport.video/")}<\/script><iframe id="sbIframe${this.gameSlug}" src="${pathJoin([window.location.origin, 'embed-game', this.gameSlug, this.currentVideo.slug])}"  scrolling="no" frameborder="0" style="width: 100%;" onload="sbIframeInit()"><\/iframe>`
          return `<script type="text/javascript">function sbIframeInit(){const e=document.getElementById("sbIframe${this.gameSlug}");window.addEventListener("message",function(t){"string"!=typeof t.data&&(e.height=t.data)},!1),e.contentWindow.postMessage("message","https://sport.video/")}<\/script><iframe id="sbIframe${this.gameSlug}" src="${pathJoin([window.location.origin, 'embed-game', this.gameSlug])}"  scrolling="no" frameborder="0" style="width: 100%;" onload="sbIframeInit()"><\/iframe>`

        },
        iframeEvent() {
          if (this.currentVideo)
            return `<script type="text/javascript">function sbIframeInit(){const e=document.getElementById("sbIframe${this.gameSlug}");window.addEventListener("message",function(t){"string"!=typeof t.data&&(e.height=t.data)},!1),e.contentWindow.postMessage("message","https://sport.video/")}<\/script><iframe id="sbIframe${this.gameSlug}" src="${pathJoin([window.location.origin, 'embed-highlight', this.gameSlug, this.currentVideo.slug])}"  scrolling="no" frameborder="0" style="width: 100%;" onload="sbIframeInit()"><\/iframe>`
          return `<script type="text/javascript">function sbIframeInit(){const e=document.getElementById("sbIframe${this.gameSlug}");window.addEventListener("message",function(t){"string"!=typeof t.data&&(e.height=t.data)},!1),e.contentWindow.postMessage("message","https://sport.video/")}<\/script><iframe id="sbIframe${this.gameSlug}" src="${pathJoin([window.location.origin, 'embed-highlight', this.gameSlug])}"  scrolling="no" frameborder="0" style="width: 100%;" onload="sbIframeInit()"><\/iframe>`
        },
        isLast() {
          if (this.simple)
            return true;
          else
            return this.currentVideoIndex == this.filteredVideos.length - 1;
        },
        isFirst() {
          if (this.simple)
            return true;
          else
            return this.currentVideoIndex == 0;
        },
        awayClub() {
          return this.currentVideo
        },
        homeClub() {
          return this.currentVideo
        },
        filteredVideos() {
          return filters[this.filter](this.allVideos, this.maxFilteredVideos)
        },
        isAd() {
          return !this.adData.isAdblock && !this.adDisabled
        },
        currentVideoUrl() {
          if (this.currentVideo && this.currentVideo.video != null && this.currentVideo.video.urls.edges.length > 0) {

            // QUICKFIX TO BYPASS CACHE SERVER
            // return this.currentVideo.video.fallbackUrl
            // QUICKFIX TO BYPASS CACHE SERVER

            let mp4video = this.currentVideo.video.urls.edges.filter((item) => {
              return item.node.type === 'mp4' && item.node.brandingId === 0
            });
            if (mp4video.length > 0) {
              return mp4video[0].node.url;
            }
          }
          return null;
        }
      },
      created() {
        if (typeof google === "undefined" || !google) {
          this.adData.isAdblock = true;
        }
        this.iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);

        setInterval(() => {
          EventBus.$emit('sendHeightMessage');
        }, 600);

        window.addEventListener('resize', () => {
          if ((this.$mq === 'sm') || (this.$mq === 'md')) {
            if (this.current_view === 'desktop') {
              this.maxFilteredVideos = 5;
              this.current_view = 'mobile';
            }
          } else {
            if (this.current_view === 'mobile') {
              this.maxFilteredVideos = 500;
              this.current_view = 'desktop';
            }
          }
          EventBus.$emit('sendHeightMessage');
        });
        this.is_touchscreen = (() => {
          var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

          var mq = function (query) {
            return window.matchMedia(query).matches;
          }

          if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
          }

          // include the 'heartz' as a way to have a non matching MQ to help terminate the join
          // https://git.io/vznFH
          var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
          return mq(query);
        })();
      },
      mounted() {
        this.scrollToComment()
        if ((this.$mq === 'sm') || (this.$mq === 'md')) {
          this.maxFilteredVideos = 5;
          this.current_view = 'mobile';
        }
        this.currentUrl = window.location.href;
        let header_banner_link = document.getElementById('header-banner-link');
        if (header_banner_link) {
          let self = this;
          document.getElementById('header-banner-link').addEventListener('click', () => {
            this.trackEvent('click', 'headerImage', self.header.image_url, {
              'game': this.game.name,
              'game_id': getOriginalId(this.game.id)
            });
          })
        }
        this.$root.$on('social_shares_open', (network, url) => {
          this.getBrandedThumbnail(network, url)
        });
        setInterval(() => {
          this.getVideoTime()
        }, 1000)
        setInterval(() => {
          this.updateWatchTime()
        }, this.watchTimeConstant * 1000)

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (!('pictureInPictureElement' in document) || document.pictureInPictureElement === null) {
                if (entry.isIntersecting) {
                  this.player.play();
                } else {
                  this.player.pause();
                }
              }
            });
          },
            {
          threshold: 0.5,
        });
      },
      beforeDestroy() {
        if (this.observer) {
          this.observer.disconnect();
        }
        this.player.dispose();
        this.playerLive.dispose();
      },
      apollo: {
        allGames: {
          query: getFilterItemsQuery(),
          loadingKey: 'loading',
          variables() {
            return {
              gameSlug: this.game ? this.game.slug : this.gameSlug,
                        parentSlug: this.parentSlug ? this.parentSlug : (this.game ? this.game.slug : this.gameSlug)
            }
          },
          skip() {
            return this.gameSlug == ''
          },
          result({data}) {
            this.gameDuplicate = {
              'homeGoals': data.allGames.edges[0].node.homeGoals,
              'awayGoals': data.allGames.edges[0].node.awayGoals
            };
          },
          refetch({data}) {
            this.gameDuplicate = {
              'homeGoals': data.allGames.edges[0].node.homeGoals,
              'awayGoals': data.allGames.edges[0].node.awayGoals
            };
          }
        },
        allVideos: {
          query: getFilterItemsQuery(),
          loadingKey: 'loading',
          variables() {
            return {
              gameSlug: this.game ? this.game.slug : this.gameSlug,
                        parentSlug: this.parentSlug ? this.parentSlug : (this.game ? this.game.slug : this.gameSlug)
            }
          },
          skip() {
            return this.gameSlug == ''
          },
          result({data}) {
            this.game = data.allGames.edges[0].node;
            this.cams = data.cams.edges.map(item => item.node);
            if (this.game.parent)
              this.cams.unshift(this.game.parent)
            else
              this.cams.unshift(this.game)
            if (this.firstRun) {
              this.tab = 'cam' + this.cams.find((el) => {return el.slug === this.gameSlug}).id + (this.is_livestream ? 'live' : 'highlights')
              let created = new Date(this.game.liveCreated)
              this.baseTime = (Math.abs(Date.now() - created) / 1000) - 5
              this.firstRun = false
            }
            this.gameDuplicate = {
              'homeGoals': data.allGames.edges[0].node.homeGoals,
              'awayGoals': data.allGames.edges[0].node.awayGoals
            };
            this.allVideos = data.allVideos.edges.map(item => item.node);
            data.campaigns.edges.forEach((item) => {
              let i;
              for (i = 0; i < item.node.priority; i++) {
                if (item.node.prerollVideo != null) {
                  this.adData.video.allAdWithPriority.push({
                    ...item.node.prerollVideo
                  });
                }
                if (item.node.banner != null) {
                  this.adData.banner.allAdWithPriority.push({
                    ...item.node.banner
                  });
                }
                if (item.node.overlayBanner != null) {
                  this.adData.overlayBanner.allAdWithPriority.push({
                    ...item.node.overlayBanner
                  });
                }
                if (item.node.postrollImage != null) {
                  this.adData.image.allAdWithPriority.push({
                    ...item.node.postrollImage
                  });
                }
              }
            });
            EventBus.$emit('sendHeightMessage');
            this.setNextAd('overlayBanner')
            if (this.is_livestream && this.adData.overlayBanner.ad && this.overlayBannerInterval == null) {
              this.overlayBannerInterval = setInterval(() => {
                this.showOverlayBanner()
              }, (this.adData.overlayBanner.ad.frequency + this.adData.overlayBanner.ad.duration) * 1000)
            }
            if (this.refetchInterval === null) {
              this.refetchInterval = setInterval(() => {
                this.$apollo.queries.allGames.refetch()
              }, 6000 + Math.floor(Math.random() * 2000))
            }
          },
          refetch({data}) {
            this.gameDuplicate = {
              'homeGoals': data.allGames.edges[0].node.homeGoals,
              'awayGoals': data.allGames.edges[0].node.awayGoals
            };
          }
        }
      }
    }
</script>
<style lang="scss">
.vjs-ad-playing {
  .vjs-control-bar {
    display: none;
  }
}

.vast-blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--hidden {
    display: none;
  }
}

.progress-container {
  margin-top: 5px;
}

@media (min-width: 767px) {
  .progress-container {
    margin-left: 9%;
  }
}

.watermark-container {
  position: relative;
}

@media (max-width: 767px) {
  .watermark-container {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.el-tabs__header {
  margin-bottom: 1px !important;
  border: none !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
  background-color: #ffffff;
}

.el-tabs__content {
  box-shadow: 0 16px 32px 0 rgb(118 118 118 / 8%);
}

.el-tabs__item.is-active {
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.5));
}

.el-tabs__item:not(.is-active) div img {
  filter: brightness(0) saturate(100%) invert(91%) sepia(7%) saturate(9%) hue-rotate(354deg) brightness(83%) contrast(88%);
}

.el-tabs__item:not(:first-child) {
  margin-left: 6px;

  .multicam-item {
    margin-left: -1px;
    width: 120px;
  }
}

    .el-tabs__nav {
      border: none !important;
      z-index: auto!important;
    }

.board-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.score-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.board-score {
  padding: 2px;
  height: 15px;
  background: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}

.scoreboard-flag {
  width: 15%;
  height: 3px;
}

.board-time {
  width: 40%;
  height: 11px;
  background: #C0C0C0;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.2em;
  color: #000000;
  text-align: center;
}

@media (max-width: 767px) {
  .video-player-live {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 767px) {
  .el-tabs__content {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .info-overlay__banner {
    width: 320px !important;
    height: 40px !important;
    background-size: contain !important;
  }
}

.mr-negat-20 {
  margin-right: -20px;
}

.el-tabs__item:not(.is-active) .cam-label {
  background-color: grey;
}

.cam-label {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: white;
}

.el-tabs__content {
  background-color: white;
}

.cam-tab-link {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>
<style scoped>
:deep(.el-tabs__item) {
  height: 75px !important;
  padding: 0 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

:deep(.el-tabs__nav-next) {
  background-color: white;
  box-shadow: 0 16px 32px 0 hsla(0, 0%, 46.3%, .08);
  transition: background-color;
  transition-duration: .3s;
  border-radius: 50%;
  height: 21px;
  width: 20px;
  top: 27px;
  padding-top: 2px;
  padding-left: 2px;
  font-size: 16px;
}

:deep(.el-tabs__nav-next:hover) {
  background-color: #1ed360;
}

:deep(.el-icon-arrow-right) {
  color: #1ed360;
  transition: color;
  transition-duration: .3s;
  display: block;
}

:deep(.el-icon-arrow-right:hover) {
  color: white;
}

:deep(.el-tabs__nav-prev) {
  background-color: white;
  box-shadow: 0 16px 32px 0 hsla(0, 0%, 46.3%, .08);
  transition: background-color;
  transition-duration: .3s;
  border-radius: 50%;
  height: 21px;
  width: 20px;
  top: 27px;
  padding-top: 2px;
  padding-left: 2px;
  font-size: 16px;
}

:deep(.el-tabs__nav-prev:hover) {
  background-color: #1ed360;
}

:deep(.el-icon-arrow-left) {
  color: #1ed360;
  transition: color;
  transition-duration: .3s;
  display: block;
}

:deep(.el-icon-arrow-left:hover) {
  color: white;
}

:deep(.el-tabs__nav-wrap.is-scrollable) {
  padding: 0 24px;
}

:deep(.el-tabs__nav-wrap){
 margin-bottom: -2px;
}
</style>
