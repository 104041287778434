<template>
    <li v-if="getMenuTreeKeys().length > 0">
        <a :href="getToUrl(itemData.data)">{{itemData.data.name}}</a>
        <ul>
            <iframe-topbar-item v-for="menuItemId in getMenuTreeKeys()" :key="menuItemId"  :channel-slug="channelSlug" :item-data="getMenuTreeObject(menuItemId)"></iframe-topbar-item>
        </ul>
    </li>
    <li v-else><a :href="getToUrl(itemData.data)">{{itemData.data.name}}</a></li>
</template>

<script>
    import {pathJoin} from "../../utils";

    export default {
        name: "iframe-topbar-item",
        props:{
            itemData: {},
            channelSlug: {}
        },
        methods:{
            getToUrl(item) {
                if (item == null)
                    return pathJoin(['/embed', this.channelSlug]);
                else if (item.slug)
                    return pathJoin(['/embed', this.channelSlug, item.slug]);
            },
            getMenuTreeKeys(){
                return Object.keys(this.itemData.children)
            },
            getMenuTreeObject(key){
                return this.itemData.children[key];
            },
        }
    }
</script>
