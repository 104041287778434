<script>
    import Slick from 'vue-slick';

    export default {
        name: "VideoCarousel",
        components: { Slick },
         data() {
          return {
            slickOptions: {
              slidesToShow: 4,
              infinite: true,
              autoplay: false,
              prevArrow: '<button type="button" class="slider-arrow slider-arrow--prev video-slider__arrow"><i class="fas fa-chevron-left"></i></button>',
              nextArrow: '<button type="button" class="slider-arrow slider-arrow--next video-slider__arrow"><i class="fas fa-chevron-right"></i></button>',
              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 2,
                    arrows: false,
                    dots: true,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    variableWidth: false,
                    infinite: true,
                  }
                }
              ]
            },
            sportOptions: [{
                  value: 'Option1',
                  label: 'Option1'
              }, {
                  value: 'Option2',
                  label: 'Option2'
              }, {
                  value: 'Option3',
                  label: 'Option3'
              }, {
                  value: 'Option4',
                  label: 'Option4'
              }, {
                  value: 'Option5',
                  label: 'Option5'
              }],
              selectSport: '',
              selectSport2: '',
          }
        },
    }
</script>