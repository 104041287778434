<template>
    <div class="detail-filter" id="detail-filter">
        <el-select :value="isMultiValue(search_data.game_detail.filter['leagues.keyword']) ? '' : search_data.game_detail.filter['leagues.keyword']" clearable filterable
                   @blur="selectFix"
                   @change="updateFilter($event, 'leagues.keyword')"
                   :placeholder="gettext('Select league or tournament')" class="detail-filter__item">
            <label slot="prefix" class="filter-label hidden-xs">{{ gettext('League or Tournament') }}</label>
            <el-option
                    v-for="item in allLeagues"
                    :key="item.id"
                    :label="item.name"
                    :value="item.slug">
            </el-option>
        </el-select>
        <el-select :value="search_data.game_detail.filter['away_team.slug.keyword|home_team.slug.keyword']" clearable
                   filterable
                   @blur="selectFix"
                   @change="updateFilter($event, 'away_team.slug.keyword|home_team.slug.keyword')"
                   :placeholder="gettext('Select team')" class="detail-filter__item">
            <label slot="prefix" class="filter-label hidden-xs">Team</label>
            <el-option
                    v-for="item in allTeams"
                    :key="item.id"
                    :label="item.name"
                    :value="item.slug">
            </el-option>
        </el-select>
        <el-select :value="getSortItem('game_detail', 'sort_1')"
                   @change="setSort($event, 'sort_1')"
                   :placeholder="gettext('Sort by')" class="detail-filter__item">
            <label slot="prefix" class="filter-label hidden-xs">Sort by</label>
            <el-option
                    v-for="(item, index) in sort_by"
                    :key="index"
                    :label="item.name"
                    :value="item.value">
            </el-option>
        </el-select>
        <el-select :value="search_data.game_detail.filter_range['created']"
                   value-key="key"
                   @change="updateFilter($event, 'created', 'filter_range')"
                   :placeholder="gettext('Recency')" class="detail-filter__item">
            <label slot="prefix" class="filter-label hidden-xs">Recency</label>
            <el-option
                    v-for="item in date_range"
                    :key="item.value.key"
                    :label="item.name"
                    :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import gql from 'graphql-tag'
    import AbstractSportFilter from './AbstractSportFilter'
    import get from 'lodash/get'
    import merge from "lodash/merge";

    function getFilterItemsQuery() {
        return gql`
        query ChannelLeagues($channelSlug: String!, $leagueSlug: String, $leagueSlugList: [String]) {
          allLeagues(channel_Slug: $channelSlug, slug_In: $leagueSlugList) {
            edges {
              node {
                id
                name
                slug
              }
            }
          },
          allTeamsByName(club_Channel_Slug: $channelSlug, leagueteam_League_Slug: $leagueSlug){
            edges{
              node{
                id
                name
                slug
              }
            }
          }
        }`
    }

    export default {
        name: "DetailFilter",
        extends: AbstractSportFilter,
        props: {
            channelSlug: {
                type: String,
                required: true
            },
            leagues: {
                type: String,
                required: false,
                default: null
            },
        },
        data() {
            return {
                allLeagues: [],
                allTeams: [],
                sort_by: [
                    {
                        name: 'Latest',
                        value: '-created'
                    },
                    {
                        name: 'Most viewed',
                        value: {
                            "views.views": {
                                "mode": "sum",
                                "order": "desc",
                                "nested": {
                                    "path": "views"
                                }
                            }
                        }
                    }
                ],
                date_range: [
                    {
                        name: 'Any time',
                        value: {
                            key: 'any_time'
                        }
                    },
                    {
                        name: 'Past day',
                        value: {
                            key: 'past_day',
                            gte: "now-1d",
                            lte: "now"
                        }
                    },
                    {
                        name: 'Past week',
                        value: {
                            key: 'past_week',
                            gte: "now-1w",
                            lte: "now"
                        }
                    },
                    {
                        name: 'Past month',
                        value: {
                            key: 'past_month',
                            gte: "now-1M",
                            lte: "now"
                        }
                    },
                    {
                        name: 'This year',
                        value: {
                            key: 'this_year',
                            gte: "now-1y",
                            lte: "now"
                        }
                    }
                ],
                loading: false,
                index_names: ['game'],
                result_names: ['game_detail'],
                dataInitFilter: {}
            }
        },
        methods: {
            isMultiValue(data) {
                if(data){
                    return data.indexOf('|') !== -1
                }else{
                    return false;
                }
            },
            initComponent() {
                const initShown = get(window, `channelProperties.detail_games_shown.${this.$mq}`, null);
                if(initShown) {
                    this.dataInitFilter = {
                        game_detail: {
                            size: initShown
                        }
                    }
                }
                // call super
                AbstractSportFilter.methods.initComponent.call(this);
            },
        },
        apollo: {
            allLeagues: {
                query: getFilterItemsQuery(),
                loadingKey: 'loading',
                variables() {
                    var ret_data = {
                        channelSlug: this.channelSlug,
                    };
                    if (this.leagues != null) {
                      ret_data['leagueSlug'] = this.leagues;
                      ret_data['leagueSlugList'] = this.leagues.split(',');
                    }
                    return ret_data
                },
                result({data}) {
                    this.allLeagues = data.allLeagues.edges.map(item => item.node);
                    this.allTeams = data.allTeamsByName.edges.map(item => item.node);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flag {
        width: 15px;
    }
</style>