<template>
    <div class="sport-filter">
        <el-input :placeholder="gettext('Search for channels, leagues or clubs...')"
                  :value="search_text_full"
                  ref="searchinput"
                  @keyup.enter.native="searchHandler()"
                  auto-complete="NoAutocomplete"
                  @input="searchText($event); search_text_full = $event;"
                  class="sport-filter__item sport-filter__form sport-filter__input">
            <i class="el-icon-search el-input__icon hidden-xs" slot="prefix"></i>
            <label slot="suffix" class="hidden-xs">Search</label>
        </el-input>
        <el-select :value="search_data.channel.filter['sports_slug']" clearable filterable
                   @blur="selectFix"
                   @keyup.enter.native="searchHandler()"
                   @change="updateFilter($event, 'sports_slug')"
                   :placeholder="gettext('Choose sport')"
                   auto-complete="NoAutocomplete"
                   class="sport-filter__item sport-filter__form sport-filter__select">
            <label slot="prefix" class="filter-label hidden-xs">Sport</label>
            <el-option
                    v-for="item in activeSports"
                    :key="item.id"
                    :label="item.name"
                    :value="item.slug">
                <div class="country-option">
                    <i class="fa" :class="item.icon"></i>
                    <p>{{ item.name }}</p>
                </div>
            </el-option>
        </el-select>
        <el-select :value="search_data.channel.filter['country_slug']" clearable filterable
                   @blur="selectFix"
                   @keyup.enter.native="searchHandler()"
                   @change="updateFilter($event, 'country_slug')"
                   :placeholder="gettext('Your country')"
                   auto-complete="NoAutocomplete"
                   class="sport-filter__item sport-filter__form sport-filter__select sport-filter__select--noborder">
            <label slot="prefix" class="filter-label hidden-xs"
                   @change="updateFilter($event, 'country_icon')">Country</label>
            <el-option
                    v-for="item in activeCountries"
                    :key="item.id"
                    :label="item.name"
                    :value="item.slug">
                <div class="country-option">
                    <img :src="item.flag" :alt="item.code">
                    <p>{{ item.name }}</p>
                </div>
            </el-option>
        </el-select>
        <div class="sport-filter__item  sport-filter__button">
            <el-button @keyup.enter.native="searchHandler()" @click="searchHandler()" type="primary"
                       icon="el-icon-search">{{ gettext('Search') }}
            </el-button>
        </div>
    </div>
</template>

<script>
    import AbstractSportFilter from './AbstractSportFilter';
    import debounce from 'lodash/debounce';
    import {pathJoin, getQueryStringValue} from './../../utils';
    import {main_filter_mixin} from './main_filter_mixin';

    export default {
        name: "MainSportFilter",
        mixins: [main_filter_mixin],
        extends: AbstractSportFilter,
        props: {
            staticRedirect: {
                type: Boolean,
                default: false
            },
            isMainCenterFilter: {
                default: false
            }
        },
        methods: {
            searchText: debounce(function (value) {
                if (value.length >= 3 || value.length == 0) {
                    this.updateFilter(value, this.full_text_fields, 'full_text')
                }
            }, 300),
            searchHandler() {
                if (this.staticRedirect) {
                    let sport = this.search_data.channel.filter['sports_slug'];
                    let country = this.search_data.channel.filter['country_slug'];
                    let base_url = pathJoin([window.urls.channelList, country, sport]);
                    if (this.search_text_full) {
                        window.location = `${base_url}?search=${this.search_text_full}`;
                    } else {
                        window.location = base_url;
                    }
                } else {
                    this.$scrollTo(document.getElementById("list-main-filter"), 500);
                }
            },
            updateFilterPostHook(value, fields, type) {
                let sport = this.search_data.channel.filter['sports_slug'];
                let country = this.search_data.channel.filter['country_slug'];
                let search_name = this.search_data.channel.full_text['name'];
                let base_url = pathJoin([window.urls.channelList, country, sport]);
                if (search_name != null && search_name.length > 0) {
                    base_url = pathJoin([base_url, `?search=${search_name}`]);
                }
                if (!this.staticRedirect) {
                    history.replaceState({}, null, base_url);
                }
            }
        },
        data() {
            return {
                loading: false,
                index_names: ['channel', 'league', 'game', 'team'],
                result_names: ['channel', 'league', 'game', 'team'],
                full_text_fields: ['name', 'name.autocomplete', 'name.naked', 'name.autocomplete_naked'],
                search_text_full: '',
                dataInitFilter: {
                    channel: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country
                        }
                    }
                },
                focused: false
            }
        },
        created() {
            let search = getQueryStringValue("search");
            let result_filter = {};
            let full_text_boost = {
                'name': 3,
                'name.autocomplete': 3,
                'name.naked': 3,
                'name.autocomplete_naked': 3,
            };
            this.result_names.forEach((result_name) => {
                result_filter[result_name] = {
                    full_text_boost: {
                        ...full_text_boost
                    }
                };
            });
            if (search.length > 0) {
                let full_text_filter = {};
                this.full_text_fields.forEach((item) => {
                    full_text_filter[item] = search;
                });
                this.result_names.forEach((result_name) => {
                    result_filter[result_name] = {
                        full_text: {
                            ...full_text_filter
                        },
                        ...result_filter[result_name]
                    };
                });
            }
            this.$store.dispatch('filter_store/setFilterNoCall', result_filter);
            if(this.search_data.channel.full_text['name']){
                this.search_text_full = this.search_data.channel.full_text['name'];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .country-option {
        img {
            max-width: 15px;
        }
        p {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }
        i, img {
            margin-right: 0.5rem;
        }
    }
</style>