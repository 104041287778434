import axios from "axios";
import {getOriginalId} from '../../utils';

export var main_filter_mixin = {
    data() {
        return {
            active_sport_ids: [],
            active_country_ids: [],
        }
    },
    computed: {
        activeCountries() {
            if (this.allCountries == null || this.active_country_ids.length === 0) {
                return [];
            } else {
                return this.allCountries.filter(item => this.active_country_ids.includes(getOriginalId(item.id)));
            }
        },
        activeSports() {
            if (this.allSports == null || this.active_sport_ids.length === 0) {
                return [];
            } else {
                return this.allSports.filter(item => this.active_sport_ids.includes(getOriginalId(item.id)));
            }
        },
        selectedCountry() {
            try {
                return this.search_data.channel.filter['country_slug'];
            } catch (e) {
                return undefined;
            }
        },
        selectedSport() {
            try {
                return this.search_data.channel.filter['sports_slug'];
            } catch (e) {
                return undefined;
            }
        }
    },
    watch: {
        selectedCountry: {
            immediate: true,
            handler(newVal) {
                this.filterSportAndCountries(newVal, 'country');
            }
        },
        selectedSport: {
            immediate: true,
            handler(newVal) {
                this.filterSportAndCountries(newVal, 'sport');
            }
        }
    },
    methods: {
        filterSportAndCountries(value, type) {
            let param = value !== undefined ? value : '';
            let url = null;
            let destination = null;
            if (type === 'sport') {
                url = `${window.urls.activeCountries}${param}`;
                destination = 'active_country_ids';
            }
            if (type === 'country') {
                url = `${window.urls.activeSports}${param}`;
                destination = 'active_sport_ids';
            }
            if (url === null) {
                return
            }
            axios.get(url)
                .then((response) => {
                    this[destination] = response.data['ids'];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    }
}