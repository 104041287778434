<script>
    export default {
        name: "create-league",
        props: {
            initData: {
                type: Array
            },
            startIndex: {
                type: Number
            }
        },
        data() {
            let tmpArray = this.initData.slice(2);
            let resultInit = []
            tmpArray.forEach((item, index) => {
                let new_index = this.startIndex + index + 1
                resultInit.push({
                    logo_id: 'id_leagueteam_set-' + new_index + '-club_logo',
                    logo_name: 'leagueteam_set-' + new_index + '-club_logo',
                    title_id: 'id_leagueteam_set-' + new_index + '-club_name',
                    title_name: 'leagueteam_set-' + new_index + '-club_name',
                    club_name: item.club_name,
                    club_logo: item.club_logo,
                });
            });
            return {
                additional_inlines: resultInit,
                initialImage: null,
                start_index: 1
            }
        },
        mounted() {
            document.getElementById('id_leagueteam_set-TOTAL_FORMS').value = parseInt(document.getElementById('id_leagueteam_set-TOTAL_FORMS').value) + this.additional_inlines.length;
        },
        methods: {
            addClub() {
                var l = this.startIndex  + this.additional_inlines.length + 1;

                this.additional_inlines.push({
                    logo_id: 'id_leagueteam_set-' + l + '-club_logo',
                    logo_name: 'leagueteam_set-' + l + '-club_logo',
                    title_id: 'id_leagueteam_set-' + l + '-club_name',
                    title_name: 'leagueteam_set-' + l + '-club_name',
                    club_name: '',
                    club_logo: ''
                });
                document.getElementById('id_leagueteam_set-TOTAL_FORMS').value = parseInt(document.getElementById('id_leagueteam_set-TOTAL_FORMS').value) + 1;
            },
            removeClub(index) {
                this.additional_inlines.splice(index, 1);
                this.additional_inlines.forEach((item, index) => {
                    Object.keys(item).forEach((key) => {
                        this.additional_inlines[index][key] = item[key].replace(/_set-[0-9]*-/, `_set-${this.startIndex + index + 1}-`)
                    })
                })
                document.getElementById('id_leagueteam_set-TOTAL_FORMS').value = parseInt(document.getElementById('id_leagueteam_set-TOTAL_FORMS').value) - 1;
            },
            b64toBlob(b64Data, contentType = '', sliceSize = 512) {
                const byteCharacters = atob(b64Data)
                const byteArrays = []

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize)

                    const byteNumbers = new Array(slice.length)
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i)
                    }

                    const byteArray = new Uint8Array(byteNumbers)

                    byteArrays.push(byteArray)
                }

                const blob = new Blob(byteArrays, {type: contentType})
                return blob
            },
            createImage(file, ev) {
                let reader = new FileReader()

                reader.onload = (e) => {
                    this.initialImage = e.target.result
                    let blob = this.b64toBlob(this.initialImage.split(',')[1], 'image/jpeg')
                    this.$emit('uploadFinished', blob)
                    ev.style.backgroundImage = "url('" + this.initialImage + "')";
                }

                reader.readAsDataURL(file)
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                    return
                }
                let label = e.target.parentElement.getElementsByTagName('label')[0]
                this.createImage(files[0], label)
                label.classList.add('file-input-plus--filled')
            },
        }
    }
</script>