<script>
    export default {
        name: "navbar",
        data() {
            return {
                appsDownloadModal: false,
                open: false,
                options: [{
                    value: 'Option1',
                    label: 'Option1'
                }, {
                    value: 'Option2',
                    label: 'Option2'
                }, {
                    value: 'Option3',
                    label: 'Option3'
                }, {
                    value: 'Option4',
                    label: 'Option4'
                }, {
                    value: 'Option5',
                    label: 'Option5'
                }],
                select1: '',
                open_list: false,
                showTopBar: false,
                openBar: false,
                setOpacity: false,
                navbarShadow: false,
            }
        },
        watch: {
            open(newVal){
                if (this.$mq === 'sm' || this.$mq === 'md') {
                    if(newVal){
                        document.body.classList.add("no-scroll")
                        document.documentElement.classList.add("no-scroll")
                    }
                    else{
                        document.body.classList.remove("no-scroll")
                        document.documentElement.classList.remove("no-scroll")
                    } 
                }
            }
        },
        methods: {
            handleScroll() {
                let filterPosition = document.getElementById('list-main-filter').getBoundingClientRect().top;
                let navbarHeight = document.getElementById('navbar').clientHeight;
                if (filterPosition < navbarHeight+50 ) {
                    this.setOpacity = true;
                } else {
                    this.setOpacity = false;
                }
                if (filterPosition < navbarHeight+60) {
                    this.showTopBar = true;
                }
                else {
                    this.showTopBar = false;
                }
                if (filterPosition < -navbarHeight) {
                    this.openBar = true;
                } else {
                    this.openBar = false;
                }

            },
            navSetStyles() {
                let navbarHeight = document.getElementById('navbar').clientHeight;
                let windowPosition = window.pageYOffset;
                if (navbarHeight <= windowPosition) {
                    this.navbarShadow = true;

                } else {
                    this.navbarShadow = false;

                }
            },
            openMenu() {
                this.open = true;
            },
            closeMenu() {
                this.open = false;
            },
            openList() {
                this.open_list = !this.open_list;
            },
            scrollToDownload: function () {
                this.open = false;
                this.$scrollTo(document.getElementsByClassName("cta-tile")[0], 500, {offset: -35});
            },
            getStartedClick() {
               if (window.hasOwnProperty('gtag')) {
                  window.gtag('event', 'get_started_main_menu',
                      {
                          device: window.navigator.userAgent
                      });
              }
           },
           closeModal() {
            this.appsDownloadModal = false;
            document.querySelector("html").classList.remove("modal-open");
          },
        },
        created() {
            if (document.getElementById('list-main-filter')) {
                window.addEventListener('scroll', this.handleScroll);
            } else {
                window.addEventListener('scroll', this.navSetStyles);
            }
        },
        mounted() {
          let self = this;
          let app_download_modal = document.getElementById('app_download_modal_link')
          if (app_download_modal){
            app_download_modal.addEventListener('click', function ($event){
              $event.preventDefault();
              self.appsDownloadModal = true;
            })
          }
        }
    }
</script>