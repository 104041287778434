'use strict';

import videojs from 'video.js';

let Component = videojs.getComponent('Component');

class PaintOverlay extends Component {

    constructor(player, options) {
        super(player, options);
        this.hide();
    }

    createEl() {
        return super.createEl('div', {
            className: 'player-overlay'
        });
    }
}

videojs.registerComponent('PaintOverlay', PaintOverlay);
