'use strict';

import videojs from 'video.js'
import _ from "lodash";

let Component = videojs.getComponent('Component');

class LiveScoreboard extends Component {

    constructor(player, options) {
        super(player, options);
    }

    createEl() {
        let el = super.createEl('div');
        el.appendChild(document.getElementById('live-scoreboard').childNodes[0]);
        return el;
    }

}

videojs.registerComponent('LiveScoreboard', LiveScoreboard);