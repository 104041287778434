<script>
    import AbstractSportFilter from './AbstractSportFilter'

    export default {
        name: "FooterFilters",
        extends: AbstractSportFilter,
        data() {
            return {
                result_names: ['top_sports_footer', 'top_leagues', 'top_channels'],
                index_names: ['top_sports', 'league', 'channel'],
                dataInitFilter: {
                    top_sports_footer: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country,
                        },
                        size: 5
                    },
                    top_leagues: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country,
                        },
                        sort: {
                            sort_1: {
                                "total_views": {
                                    "order": "desc"
                                }
                            }
                        },
                        size: 5
                    },
                    top_channels: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country,
                        },
                        sort: {
                            sort_1: {
                                "total_views": {
                                    "order": "desc"
                                }
                            }
                        },
                        size: 5
                    },
                },
            }
        }
    }
</script>
