'use strict';

import videojs from 'video.js';

let MenuItem = videojs.getComponent('MenuItem');

class SettingsMenuItem extends MenuItem {

    constructor(player, options) {
        super(player, options);
        this.hasSubmenu_ = false;
        this.menu_ = options.menu;
        this.parent_ = options.parent;
    }

    handleClick() {
        let menuItem = this;
        if (menuItem.hasSubmenu()) {
            _.each(menuItem.menu().children(), function (child) {
                if (child.parent() === menuItem) {
                    child.show();
                } else {
                    child.hide();
                }
            });
        } else if (menuItem.parent().hasSubmenu && menuItem.parent().hasSubmenu()) {
            _.each(menuItem.menu().children(), function (child) {
                if (child.parent() === menuItem.parent().parent()) {
                    child.show();
                } else {
                    child.hide();
                }
            });
        }
    }

    hasSubmenu() {
        return this.hasSubmenu_;
    }

    menu() {
        return this.menu_;
    }

    parent() {
        return this.parent_;
    }

}

videojs.registerComponent('SettingsMenuItem', SettingsMenuItem);
