'use strict';

import videojs from 'video.js';

let Button = videojs.getComponent('Button');

export default class SlowMotionButton extends Button {
    constructor(player, options) {
        super(player, options);
        this.isActive = false;
        this.slowMotionRate = options.slowMotionRate;
		this.on(player,"play",this.render);
		this.on(player,"ratechange", (event) => {
		    this.isActive = this.player().playbackRate() < 1;
		    this.render();
        });
        this.render();
    }

    handleClick() {
        super.handleClick();
        let playbackRate;
        if(this.isActive) {
            playbackRate = 1;
        } else {
            playbackRate = this.slowMotionRate;
        }
        this.player().playbackRate(playbackRate);
    }

    render() {
        const className = this.isActive ? 'active' : '';
        if (window.matchMedia('screen and (max-width: 767px)').matches) {
            this.contentEl().innerHTML = "<div class=\"button--slow " + className + "\">SM</div>";
        }
        else{
            this.contentEl().innerHTML = "<div class=\"button--slow " + className + "\">SM</div>";
        }
    }
}

videojs.registerComponent('SlowMotionButton', SlowMotionButton);