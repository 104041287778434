'use strict';

import videojs from 'video.js';
let Button = videojs.getComponent('Button');

class Navigation extends Button {

    constructor(player, options) {
        super(player, options);
        this.on(player, 'useractive', this.handleUserActive);
        this.on(player, 'userinactive', this.handleUserInactive);
        this.hide();
    }

    handleUserActive() {
        this.removeClass('video-control__navigation-button--inactive');
    }

    handleUserInactive() {
        if (!this.player().paused() && !this.player().ended()) {
            this.addClass('video-control__navigation-button--inactive');
        }
    }

}

videojs.registerComponent('Navigation', Navigation);