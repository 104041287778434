'use strict';

import videojs from 'video.js';
import template from 'lodash/template';

let Button = videojs.getComponent('Button');

export default class ClubInfoBig extends Button {

    constructor(player, options) {
        super(player, options);
        this.updateTeams(options.homeClub, options.awayClub);
        this.on('click', this.handleClick);
        this.on(player, 'play', this.hide);
    }

    createEl() {
        return super.createEl('div', {
            className: 'video-club-info'
        });
    }

    handleClick() {
        this.hide();
        this.player().play();
        this.player().trigger('showNavigation');
    }

    updateTeams(homeclub, awayclub) {
        let template_var = template(document.getElementById('club-info-big-template').innerHTML);
        this.el().innerHTML = template_var({
            awayClub: awayclub,
            homeClub: homeclub
        });
    }

}

videojs.registerComponent('ClubInfoBig', ClubInfoBig);
