<script>
    export default {
        name: "Blog",
        data() {
            return {
                currentPage1: 1,
            }
        },
        methods: {
            handleCurrentChange(val) {
                window.location.href = location.protocol + '//' + location.host + location.pathname + '?page=' + val
                console.log(`current page: ${val}`);
            }
        },
    }
</script>