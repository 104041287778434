<template>
    <div class="section-header">
        <div class="section-header__title">
            <h3 v-text="gettext('Most viewed videos from')" class="hidden-xs"></h3>
            <h3 v-text="gettext('Most viewed videos')" class="visible-xs"></h3>
            <el-select
                    :value="search_data.most_viewed.filter['country_slug']"
                    @blur="selectFix"
                    clearable
                    filterable
                    @change="updateFilter($event, 'country_slug')"
                    size="small"
                    :placeholder="gettext('Select option')"
                    class="text-select hidden-xs"
                    ref="select1">
                <el-option
                        v-for="item in activeCountries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.slug">
                </el-option>
            </el-select>
            <div class="section-header__hidden-label" ref="referenceLabel" v-text="selectLabel">
            </div>
        </div>
        <div class="section-header__link hidden-xs">
            <el-select
                    :value="search_data.most_viewed.filter['sports_slug']"
                    @blur="selectFix"
                    clearable
                    filterable
                    @change="updateFilter($event, 'sports_slug')"
                    size="small"
                    :placeholder="gettext('All sports')"
                    class="select select--primary">
                <el-option
                        v-for="item in allSports"
                        :key="item.id"
                        :label="item.name"
                        :value="item.slug">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    import AbstractSportFilter from './AbstractSportFilter'
    import {main_filter_mixin} from './main_filter_mixin';

    export default {
        name: "MostViewedGamesFilter",
        mixins: [main_filter_mixin],
        extends: AbstractSportFilter,
        data() {
            return {
                result_names: ['most_viewed'],
                dataInitFilter: {
                    most_viewed: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country
                        },
                        sort: {
                            sort_1: "-total_views"
                        },
                        size: 50
                    },

                },
                index_names: ['game'],
            }
        }
    }
</script>
