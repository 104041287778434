<script>
    import Slick from "vue-slick";

    export default {
        name: "subscription",
        components: {Slick},
        data() {
            return {
                slickChooseSubscription: {
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 9999,
                            settings: "unslick",
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                arrows: false,
                                dots: true,
                                dotsClass: 'primary-slick-dots',
                                infinity: false,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                infinity: false,
                                arrows: false,
                                dots: true,
                                dotsClass: 'primary-slick-dots',
                            }
                        }
                    ]
                },
                subscription_public_xs:  true,
                subscription_private_xs: true,
                subscription_whitelabel_xs: true,
            }
        },
        mounted() {
            if (this.$mq === 'sm') {
                this.subscription_public_xs = false;
                this.subscription_private_xs = false;
                this.subscription_whitelabel_xs = false;
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            }
        }
    }
</script>