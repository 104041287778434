<template>
    <div class="section-header">
        <div class="section-header__title">
            <h3 v-text="gettext('Recent videos from')" class="hidden-xs"></h3>
            <h3 v-text="gettext('Recent videos')" class="visible-xs"></h3>
            <el-select
                    :value="search_data.recent_games.filter['country_slug']"
                    @blur="selectFix"
                    clearable
                    filterable
                    @change="updateFilter($event, 'country_slug')"
                    size="small"
                    :placeholder="gettext('World')"
                    class="text-select hidden-xs"
                    ref="select1">
                <el-option
                        v-for="item in activeCountries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.slug">
                </el-option>
            </el-select>
            <div class="section-header__hidden-label" ref="referenceLabel" v-text="selectLabel">
            </div>
        </div>
        <div class="section-header__link hidden-xs">
            <el-select
                    :value="search_data.recent_games.filter['sports_slug']"
                    @blur="selectFix"
                    clearable
                    filterable
                    @change="updateFilter($event, 'sports_slug')"
                    size="small"
                    :placeholder="gettext('All sports')"
                    class="select select--primary">
                <el-option
                        v-for="item in allSports"
                        :key="item.id"
                        :label="item.name"
                        :value="item.slug">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    import AbstractSportFilter from './AbstractSportFilter'
    import {main_filter_mixin} from './main_filter_mixin';

    export default {
        name: "RecentGamesFilter",
        mixins: [main_filter_mixin],
        extends: AbstractSportFilter,
        data() {
            return {
                result_names: ['recent_games'],
                dataInitFilter: {
                    recent_games: {
                        filter: {
                            country_slug: this.$store.state.filter_store.country,
                        },
                        filter_range: {
                          total_views: {
                            key: "morethan15",
                            gte: 15
                          }
                        },
                        sort: {
                            sort_1: "-created"
                        },
                        size: 50
                    }
                },
                index_names: ['game'],
            }
        }
    }
</script>
