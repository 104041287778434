<script>
    import debounce from 'lodash/debounce';
    import axios from "axios";
    import {SpinnerMixin} from "../mixins/SpinnerMixin";

    export default {
        name: "Registration",
        mixins: [SpinnerMixin],
        props: {
            entity_type_init: {
                default: null
            },
            metarole_init: {
                default: null
            },
            country_init: {
                default: null
            },
            fupa_countries: {
                type: Array,
                default: []
            },
            is_social: {
                default: false
            },
            invite_init: {
                default: null
            },
        },
        data() {
            return {
                email_value: '',
                entity_type: this.entity_type_init,
                metarole: this.metarole_init,
                metaroles: {'1': {
                              "club_official": "Club Official",
                              "coach": "Coach",
                              "player": "Player",
                              "cameraman": "Cameraman",
                             },
                          '2': {
                              "league_administrator": "League Administrator",
                              "team_manager": "Team Manager",
                              "coach": "Coach",
                              "player": "Player",
                              "cameraman": "Cameraman",
                          },
                          '4':{
                               "executive": "Executive",
                               "club_representative": "Club Representative",
                               "marketing_and_media": "Marketing and Media",
                               "cameraman": "Cameraman",
                          },
                          '3':{
                               "organizer": "Organizer",
                               "club_representative": "Club Representative",
                               "broadcast_coordinator": "Broadcast Coordinator",
                               "marketing_and_media": "Marketing and Media",
                               "cameraman": "Cameraman",
                          },
                          '5': {
                            "club_official": "Club Official",
                            "coach": "Coach",
                            "player": "Player",
                            "cameraman": "Cameraman",
                            "fan": "Fan",
                            "other": "Other"
                          }},
                country: this.country_init,
                is_invited: false,
                create_account: true,
                account_to_join: null,
                invite: this.invite_init
            }
        },
        watch:{
          entity_type(newVal) {
            this.$refs.metaroleselect.choices = this.metaroles[newVal]
            if(!this.$refs.metaroleselect.choices.hasOwnProperty(this.metarole))
              this.metarole = null
          }
        },
        methods: {
            fetchByEmail: debounce(function ({target}) {
                let url = this.$store.state.global_data.urls['api:invite-exists']();
                let value = target.value
                axios.get(
                    url, {
                        params: {
                            email: target.value
                        }
                    }
                ).then((response) => {
                    if (target.value === value){
                      this.is_invited = response.data.exists;
                      this.create_account = !response.data.exists;
                      this.invite = response.data.invite
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }, 300),
        },
        mounted() {
            this.loading = false;
            if (!this.is_social)
                this.fetchByEmail({target: this.$refs.emailinput});
            this.$refs.metaroleselect.choices = null
            let inputs = document.getElementsByClassName('el-input__inner')
            for(let i = 0;i < inputs.length; i++){
              inputs[i].addEventListener("click", function sendGaEvent(event) {
              if (window.hasOwnProperty('gtag')) {
                window.gtag('event', 'click_'+inputs[i].name,
                  {
                      device: window.navigator.userAgent
                  });
                }
            })
              inputs[i].addEventListener("change", function sendGaEvent(event) {
              if (window.hasOwnProperty('gtag')) {
                window.gtag('event', 'fill_'+inputs[i].name,
                  {
                      device: window.navigator.userAgent
                  });
                }
            })
            }
            document.getElementById('submit_form').addEventListener("click", function sendGaEvent(event) {
              if (window.hasOwnProperty('gtag')) {
                window.gtag('event', '1st_form_submit',
                  {
                      device: window.navigator.userAgent
                  });
                }
            })
        }
    }
</script>
<style lang="scss">
  .el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1;
    }
}
</style>