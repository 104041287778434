<template>
    <div class="video-slider__carousel">
        <div class="row">
            <slick ref="slick" :options="slickOptions">
                <template v-if="result_data">
                    <div v-for="item in result_data.hits" class="video-tile">
                        <a class="link" :href="goToChannel(item.league.channel.slug, item.league.slug, item.slug)">
                            <div class="video-tile__image" v-bind:style="{ backgroundImage: item.thumbnail ? 'url(' + item.thumbnail + ')' : item.is_livestream ? 'url(/static/images/livetab.svg)' : 'url(' + item.thumbnail + ')',
                                                                           backgroundColor: item.thumbnail ? 'transparent' : item.is_livestream ? 'white' : 'transparent',
                                                                           backgroundSize:  item.thumbnail ? 'cover' : item.is_livestream ? 'contain' : 'cover'}"></div>
                            <div class="video-tile__description">
                                <div class="video-tile__title">{{ item.name }}</div>
                                <ul class="video-tile__text">
                                    <li><img class="img-fallback" :data-lazy="item.league.logo != null ? item.league.logo : item.league.channel.logo">
                                        <span>{{item.league.name}}</span>
                                    </li>
                                    <li class="dot" v-if="item.league.channel.country">{{ item.league.channel.country.name }}</li>
                                </ul>
                                <ul class="video-tile__text">
                                    <li><i class="fas fa-eye"></i><span v-text="get_number_formatted(item.total_views)"></span></li>
                                    <li class="dot" v-text="get_publish_date(item.created)"></li>
                                </ul>
                            </div>
                        </a>
                    </div>
                </template>
            </slick>
        </div>
    </div>
</template>

<script>
    import AbstractResult from './AbstractResult'
    import Slick from 'vue-slick'

    export default {
        name: "SliderGamesResult",
        extends: AbstractResult,
        props: {
            result_name_init:{
                type: String,
                required: true
            }
        },
        data() {
            return {
                result_name: this.result_name_init,
                slickOptions: {
                    slidesToShow: 4,
                    lazyLoad: 'ondemand',
                    infinite: false,
                    autoplay: false,
                    prevArrow: '<button type="button" class="slider-arrow slider-arrow--prev video-slider__arrow"><i class="fas fa-chevron-left"></i></button>',
                    nextArrow: '<button type="button" class="slider-arrow slider-arrow--next video-slider__arrow"><i class="fas fa-chevron-right"></i></button>',
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                arrows: false,
                                dots: true,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                arrows: false,
                                variableWidth: false,
                                infinite: false,
                            }
                        }
                    ]
                },
            }
        },
        components: {
            Slick,
        },
        watch:{
            result_data: function () {
                  let currIndex = this.$refs.slick.currentSlide();
                  this.$refs.slick.destroy();
                  this.$nextTick(() => {
                      this.$refs.slick.create();
                      this.$refs.slick.goTo(currIndex, true)
              })
            }
        },
        methods: {
          //BYPASS CACHE SERVER
          getFallbackThumbnail(thumbnail) {
            if (thumbnail) {
              const segments = thumbnail.split('/')
              return 'https://vipr-app.s3.amazonaws.com/data/' + segments[3] + '/image/' + segments[4]
            }
            else {
              return null
            }
          }
        }
    }
</script>