<template>
</template>

<script>
    import Vue from 'vue';
    import {filterable_select_fix} from "../../utils";
    import get from 'lodash/get';
    import merge from 'lodash/merge';

    export default {
        name: "AbstractSportFilter",
        mixins: [filterable_select_fix],
        props: {
            initFilter: {
                type: Object,
                default() {
                    return null;
                }
            },
            defaultFilter: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
                index_names: [],
                result_names: [],
                selectLabel: gettext('Select option'),
            }
        },
        computed: {
            search_data() {
                return this.$store.state.filter_store.search_data
            },
            allCountries() {
                return this.$store.state.filter_store.filter_countries
            },
            allSports() {
                return this.$store.state.filter_store.all_sports
            },
        },
        watch: {
            selectLabel(newVal) {
                this.$nextTick(() => {
                    this.$refs.select1.$el.getElementsByTagName('input')[0].style.width = 'calc(3.2rem + ' + this.$refs.referenceLabel.offsetWidth + 'px )';
                });
            }
        },
        methods: {
            getSortItem(result_name, sort_name) {
                return this.$store.getters['filter_store/getSortItem'](result_name, sort_name)
            },
            gettext(text) {
                return window.gettext(text);
            },
            setSort(value, sort_name) {
                let new_search_data = {};
                for (var i in this.result_names) {
                    new_search_data[this.result_names[i]] = {
                        'sort': {
                            ...this.search_data[this.result_names[i]]['sort'],
                            [sort_name]: value
                        }
                    };
                }
                this.$store.dispatch('filter_store/setFilter', new_search_data);
            },
            updateFilterPreHook(value, fields, type) {

            },
            updateFilterPostHook(value, fields, type) {

            },
            updateFilter(value, fields, type = 'filter') {
                this.updateFilterPreHook(value, fields, type);
                let new_search_data = {};
                for (var i in this.result_names) {
                    let inner_filters = {};
                    if (Array.isArray(fields)) {
                        fields.forEach(field => {
                            inner_filters[field] = value;
                        });
                    } else {
                        inner_filters[fields] = value;
                    }
                    new_search_data[this.result_names[i]] = {
                        [type]: {
                            ...this.search_data[this.result_names[i]][type],
                            ...inner_filters
                        }
                    };
                    if (value == "") {
                        if (Array.isArray(fields)) {
                            fields.forEach(field => {
                                let default_value = get(this.defaultFilter, `${this.result_names[i]}.${type}`, null);
                                default_value = get(default_value, field, null);
                                if (default_value) {
                                    Vue.set(new_search_data[this.result_names[i]][type], field, default_value);
                                } else {
                                    Vue.delete(new_search_data[this.result_names[i]][type], field);
                                }
                            });
                        } else {
                            let default_value = get(this.defaultFilter, `${this.result_names[i]}.${type}`, null);
                            default_value = get(default_value, fields, null);
                            if (default_value) {
                                Vue.set(new_search_data[this.result_names[i]][type], fields, default_value);
                            } else {
                                Vue.delete(new_search_data[this.result_names[i]][type], fields);
                            }
                        }
                    }
                }
                this.$store.dispatch('filter_store/setFilter', new_search_data);
                this.updateFilterPostHook(value, fields, type);

                if (this.$refs.select1 !== undefined) {
                    this.$nextTick(() => {
                        if (this.$refs.select1.query && this.$refs.select1.query.length !== undefined)
                            this.selectLabel = this.$refs.select1.query;
                        else
                            this.selectLabel = gettext('Select option');
                    })
                }

            },
            initComponent() {
                this.$store.commit('filter_store/setSearchIndexes', {
                    result_names: this.result_names,
                    index_names: this.index_names
                });
                let init_filter = {};
                if (this.initFilter != null) {
                  this.dataInitFilter = this.initFilter;
                }
                if (this.dataInitFilter != null) {
                    this.result_names.forEach((result_name) => {
                        init_filter[result_name] = {
                            ...init_filter[result_name],
                            ...this.dataInitFilter[result_name],
                        };
                        init_filter[result_name].filter = {
                            ...init_filter[result_name].filter,
                            ...get(this.defaultFilter, `${result_name}.filter`, {})
                        }
                    });
                    this.$store.dispatch('filter_store/setFilter', init_filter);
                }
                if (this.$refs.select1 !== undefined) {
                    this.selectLabel = this.$refs.select1.query;
                }
            },
        },
        created() {
            this.initComponent();
        }
    }
</script>

<style lang="scss" scoped>
    .country-option {
        img {
            max-width: 15px;
        }

        p {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }
    }
</style>