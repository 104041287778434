export let SpinnerMixin = {
    data() {
        return {
            spinner: {
                color: '#fa1f63'
            },
            loader: null,
            loading: true,
        }
    },
    watch: {
        loading: {
            handler: function (newVal) {
                if (newVal) {
                    this.initLoader();
                } else {
                    if(this.loader) {
                        this.loader.hide();
                    }
                }
            }
        }
    },
    methods: {
        initLoader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: (this.$refs.spinnerContainer != undefined) ? this.$refs.spinnerContainer : null,
                canCancel: false,
                active: this.loading
            });
        }
    },
    mounted() {
        this.initLoader();
    }
};